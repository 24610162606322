import { createMuiTheme, withStyles } from "@material-ui/core/styles";
import Rating from "@material-ui/lab/Rating";

const baseGreen = "#8ec04b";

const theme = createMuiTheme({
  // typography: {
  //     fontFamily: [
  //         //'Lato',
  //         'Roboto',
  //         '-apple-system',
  //         'BlinkMacSystemFont',
  //         '"Segoe UI"',
  //         'Roboto',
  //         '"Helvetica Neue"',
  //         'Arial',
  //         'sans-serif',
  //         '"Apple Color Emoji"',
  //         '"Segoe UI Emoji"',
  //         '"Segoe UI Symbol"',
  //     ].join(','),
  // },
  palette: {
    primary: {
      main: baseGreen,
    },
  },
  overrides: {
    MuiButton: {
      root: {
        // '&': {
        //     height: '41px',
        //     padding: '0 8px'
        // },
        "&.MuiButton-fullWidth": {
          //width: '320px',
          width: "100%",
          //maxWidth: '100%'
        },
        "& > .MuiButton-label": {
          textTransform: "none",
        },
      },
      containedPrimary: {
        "&": {
          transition: "0.5s",
          border: "2px solid transparent",
          boxShadow: "none",
        },
        "&:hover": {
          backgroundColor: "#d2e6b7",
          boxShadow: "0 1px 5px rgba(22, 18, 20, 0.30)",
        },
        "&:hover > .MuiButton-label": {
          color: "#1C3E1B",
        },
        "&:active": {
          backgroundColor: "#d3e6b8",
          border: "2px solid #7bb23b",
          boxShadow: "none",
        },
        "&:active > .MuiButton-label": {
          color: "#000",
        },
        "& > .MuiButton-label": {
          color: "white",
          transition: "0.25s",
        },
        "& a": {
          color: "#fff",
          textDecoration: "none",
        },
        "&:hover a": {
          color: "#1C3E1B",
        },
      },
      outlinedPrimary: {
        "&": {
          border: "2px solid " + baseGreen,
          backgroundColor: "#fff",
        },
        "&:hover": {
          border: "2px solid " + baseGreen,
          backgroundColor: "#fff",
          boxShadow: "0 1px 5px rgba(22, 18, 20, 0.30)",
        },
        "&:active": {
          backgroundColor: "#d2e6b7",
          boxShadow: "unset",
        },

        "& > .MuiButton-label": {
          textTransform: "none",
          color: "#000",
        },
        "& .MuiButton-outlined.Mui-disabled": {
          border: "2px solid rgba(0, 0, 0, 0.12) !important",
        },
        "& a": {
          color: "#000",
          textDecoration: "none",
        },
        "&:hover a": {
          color: "#000",
        },
      },
      textPrimary: {
        "&": {
          transition: "0.5s",
          border: "2px solid transparent",
          boxShadow: "0px 1px 3px 0px rgba(50, 50, 50, 0.2)",
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        backgroundColor: "#fff",
        boxShadow: "0px 2px 5px 0px rgba(22, 18, 20, 0.25)",
        transition: "0.3s",
        // fontWeight: '400',

        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "transparent",
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
          // borderWidth: '1px',
          // borderColor: '#8ec04b'
          borderColor: "transparent",
        },
        "& .MuiOutlinedInput-inputMarginDense": {
          paddingTop: "11.5px",
          paddingBottom: "10.5px",
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderWidth: "1px",
          borderColor: baseGreen,
        },
        "&.Mui-disabled": {
          backgroundColor: "#e9eaef",
        },
        "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
          borderWidth: "1px",
          borderColor: "#dee1e3",
        },
      },
      multiline: {
        padding: " 11px 14px",
      },
    },
    MuiIconButton: {
      colorPrimary: {
        // '&, &:hover': {
        //     backgroundColor: baseGreen,
        //     color: '#fff'
        // },
        // '&:focus': {
        //     backgroundColor: '#fff'
        // },
        // '&:active': {
        //     backgroundColor: '#fff',
        //     border: '1px solid #8ec04b'
        // },
        "&.Mui-disabled": {
          backgroundColor: "#ccc !important",
        },
      },
    },
    MuiRating: {
      icon: {
        margin: "-3px 0px -3px -3px",
        "& svg": {
          fontSize: "22px",
        },
      },
      iconFilled: {
        color: baseGreen,
      },
      iconHover: {
        color: baseGreen,
      },
    },
    MuiCheckbox: {
      root: {
        "& img": {
          width: "19px",
          height: "19px",
        },
      },
    },
    MuiFormControl: {
      root: {
        "&": {
          maxWidth: "100%",
        },
      },
    },
    MuiAccordion: {
      root: {
        boxShadow: "none",
        "&$expanded": {
          boxShadow: "0px 0px 3px 0px rgba(50, 50, 50, 0.2)",
          margin: "unset",
          zIndex: 9,
          borderRadius: "4px",
        },
        "& .MuiAccordionSummary-expandIcon": {
          transform: "rotate(-90deg)",
          color: baseGreen,
        },
        "& .MuiAccordionSummary-expandIcon.Mui-expanded": {
          transform: "rotate(0)",
        },
        "& .MuiTypography-body1": {
          fontSize: "14px",
        },
      },
    },
    MuiPickersDay: {
      day: {
        "&$daySelected": {
          backgroundColor: "#d2e6b7",
          color: "#1C3E1B",
        },
        "&$daySelected:hover": {
          backgroundColor: "#d2e6b7",
        },
      },
    },
    MuiList: {
      padding: {
        "&": {
          paddingTop: 0,
          paddingBottom: 0,
        },
      },
    },
    MuiMenuItem: {
      root: {
        "&": {
          borderBottom: "1px solid #dde3e5",
          color: "#4B555F",
        },
      },
    },
  },
});

export default theme;
