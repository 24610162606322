import React from "react";
import PropTypes from "prop-types";
import styles from "./CompletePaymentAfterConfirmation.module.scss";
import { Alert } from "@material-ui/lab";
import Button from "@material-ui/core/Button";
import { ReactComponent as TpayLogo } from "../../../assets/svg/tpay_logo_blue.svg";
import DesktopMenu from "../../Shared/DesktopMenu/DesktopMenu";
import Container from "@material-ui/core/Container";
import { Redirect } from "react-router-dom";
import Header from "../../Shared/Header/Header";
import Footer from "../../Shared/Footer/Footer";
import { Scrollbars } from "rc-scrollbars";

class CompletePaymentAfterConfirmation extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      paymentInfo:
        this.props.location &&
        this.props.location.state &&
        this.props.location.state.paymentInfo
          ? this.props.location.state.paymentInfo
          : null,
    };
  }

  componentDidMount() {
    this.setState({
      timer: 3,
    });

    let timerInterval = setInterval(() => {
      if (this.state.timer > 0) {
        this.setState({
          timer: this.state.timer - 1,
        });
      } else {
        clearInterval(timerInterval);
        this.pay();
      }
    }, 1000);
  }

  pay = () => {
    window.location.href = this.state.paymentInfo.url;
  };

  render() {
    const { paymentInfo } = this.state;

    const renderContent = paymentInfo ? (
      <Container maxWidth="sm">
        <div className={styles.subtitle}>Gratulacje!</div>
        <h1 className={styles.title}>
          Twoje konto <strong>zostało potwierdzone</strong>
        </h1>

        <div className={styles.introduction}>
          <div className={styles.price}>{paymentInfo.amount} zł</div>
          <div className={styles.desc}>
            wideokonsultacja online (koszt za 30 minut konsultacji)
          </div>
        </div>

        <div className={styles.graySection}>
          Za chwilę zostaniesz przeniesiony do T.PAY
          <div className={styles.timer}>{this.state.timer}</div>
          {/*<Button*/}
          {/*    variant="outlined"*/}
          {/*    color="primary"*/}
          {/*    startIcon={<TpayLogo height="18px"/>}*/}
          {/*    onClick={this.pay}*/}
          {/*>*/}
          {/*    Szybka płatność*/}
          {/*</Button>*/}
        </div>
      </Container>
    ) : (
      <Redirect to="/" />
    );

    return (
      <div className={styles.CompletePaymentAfterConfirmation}>
        <>
          <Header user={this.props.user} />
          <div id="pageContent">
            <div id="leftSide" className="leftSide">
              {window.screen.availWidth >= 768 && (
                <Scrollbars
                  renderTrackVertical={(props) => (
                    <div {...props} className="track-vertical" />
                  )}
                  renderThumbVertical={(props) => (
                    <div {...props} className="thumb-vertical" />
                  )}
                >
                  {renderContent}
                  <Footer />
                </Scrollbars>
              )}

              {window.screen.availWidth < 768 && (
                <>
                  {renderContent}
                  <Footer />
                </>
              )}
            </div>
          </div>
        </>
      </div>
    );
  }
}
CompletePaymentAfterConfirmation.propTypes = {};

CompletePaymentAfterConfirmation.defaultProps = {};

export default CompletePaymentAfterConfirmation;
