import { List, fromJS, Map, Record } from "immutable";
import {
  SET_LAWYER_APPOINTMENTS,
  SET_LAWYER_APPOINTMENTS_LOADING,
  SET_LAWYER_APPOINTMENTS_ID,
  SET_LAWYER_APPOINTMENT_CALL,
  SET_LAWYER_APPOINTMENTS_OBJ,
  SET_LAWYER_APPOINTMENT_CALL_SERVICE_STEPS,
} from "src/actions/types";

const AppointmentState = Record<any>({
  lawyerAppointmentsList: List(),
  lawyerAppointmentsLoading: false,
  lawyerAppointmentDetailsLoading: false,
  lawyerAppointmentDetails: null,
  lawyerAppointmentActiveId: null,
  lawyerAppointmentActiveObj: null,
});

const initialState = new AppointmentState();

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_LAWYER_APPOINTMENTS: {
      const { appointmentsList } = action.payload;
      //   return {
      //     ...state,
      // 	lawyerAppointmentsList: fromJS(appointmentsList)
      //   };

      return state.set("lawyerAppointmentsList", fromJS(appointmentsList));
    }
    case SET_LAWYER_APPOINTMENTS_LOADING: {
      return state.set("lawyerAppointmentsLoading", action.payload);
      return {
        ...state,
        lawyerAppointmentsLoading: action.payload,
      };
    }
    case SET_LAWYER_APPOINTMENT_CALL: {
      return state.set("lawyerAppointmentDetails", fromJS(action.payload));
      return {
        ...state,
        lawyerAppointmentDetails: fromJS(action.payload),
      };
    }
    case SET_LAWYER_APPOINTMENTS_ID: {
      return state.set("lawyerAppointmentActiveId", action.payload);
      return {
        ...state,
        lawyerAppointmentActiveId: action.payload,
      };
    }
    case SET_LAWYER_APPOINTMENTS_OBJ: {
      return state.set("lawyerAppointmentActiveObj", action.payload);
      return {
        ...state,
        lawyerAppointmentActiveObj: action.payload,
      };
    }
    case SET_LAWYER_APPOINTMENT_CALL_SERVICE_STEPS:
      return state.setIn(
        ["lawyerAppointmentDetails", "service", "stages"],
        fromJS(action.payload)
      );

    default:
      return state;
  }
}
