import React from "react";
import { Link } from "react-router-dom";
import styles from "./Header.module.scss";
import Logo from "../../../assets/svg/logo.svg";
import { ReactComponent as MenuIcon } from "src/assets/svg/hamburger.svg";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Menu from "../Menu/Menu";
import User from "../../../services/User";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { connect } from "react-redux";
import { setNotification } from "src/actions/Main";

class Header extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  toggleMenu = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  getRefLink = () => {
    const currentUrl = window.location.href;
    const { user, match } = this.props;

    if (match && match.path.includes(":code")) {
      return currentUrl;
    }

    if (currentUrl.includes("blog")) {
      const separator = "/blog/";
      const urlArr = currentUrl.split(separator);
      return urlArr[0] + separator + user.details.refNumber + "/" + urlArr[1];
    }

    if (currentUrl.includes("dziedziny-prawa")) {
      const separator = "/dziedziny-prawa/";
      const urlArr = currentUrl.split(separator);
      return urlArr[0] + separator + user.details.refNumber + "/" + urlArr[1];
    }

    return window.origin + "/" + user.details.refNumber + "/info";
  };

  render() {
    const { isOpen } = this.state;
    const { user, displayCopy } = this.props;
    let refLink = "";

    if (user && user.dealerId && user.details) {
      refLink = this.getRefLink();
    }

    return (
      <header className={styles.Header}>
        <Link to="/" className={styles.logo}>
          <img src={Logo} alt="Wideo prawnik" />
        </Link>

        <Box display={{ xs: "block", md: "none" }}>
          <button className={styles.hamburger} onClick={this.toggleMenu}>
            <MenuIcon />
          </button>
        </Box>

        <Box display={{ xs: "none", md: "block" }}>
          {user && user.dealerId && user.details && displayCopy && (
            <>
              <CopyToClipboard text={refLink}>
                <Button variant="contained" color="primary">
                  Skopiuj link afiliacyjny do {displayCopy}
                </Button>
              </CopyToClipboard>
            </>
          )}
          {user && (
            <>
              <Button
                // className={styles.login}
                onClick={User.logout}
                color="primary"
                variant="text"
              >
                Wyloguj
              </Button>
            </>
          )}
          {!user && (
            <>
              <Link to="/login" className={styles.login}>
                <Button color="primary" variant="text">
                  Strefa Klienta
                </Button>
              </Link>
              <Link to="/lawyer/login" className={styles.login}>
                <Button color="primary" variant="contained">
                  Strefa Prawnika
                </Button>
              </Link>
              {/*<Link to="/register" className={styles.login}><Button>Zarejestruj</Button></Link>*/}
            </>
          )}
        </Box>

        <Menu
          toggleMenu={this.toggleMenu}
          isOpen={isOpen}
          user={this.props.user}
          lawyer={this.props.lawyer}
          completePrices={this.props.completePrices}
        />
      </header>
    );
  }
}
const mapDispatchToProps = {
  setNotification,
};

export default connect(undefined, mapDispatchToProps)(Header);
