import { fromJS, Record, Map } from "immutable";
import { ActionType } from "typesafe-actions";
import { IServiceFilterData } from "src/actions/Services/requests";
import * as actions from "src/actions/Filters";
import { FiltersActionTypes } from "src/action-types/Filters";
import { ImmutableMap } from "src/helpers/ImmutableHelpers";

// import { IClientListElement, IServiceListElement, ICallListElement } from 'lawpoint-common/typing/staff-panel';

export type FiltersActions = ActionType<typeof actions>;

// export type IClientData = Partial<any>;
// export type IServiceData = Partial<any>;
// export type ICallHistoryData = Partial<any>;

//tutaj pozmienialem
export interface IFiltersState {
  clientData: any;
  serviceData: any;
  callHistoryData: any;
  messagehistory: any;
}

export type FiltersState = Record<IFiltersState>;

const FiltersState = Record<IFiltersState>({
  clientData: Map(),
  serviceData: Map(),
  callHistoryData: Map(),
  messagehistory: Map(),
});

const initialState: FiltersState = new FiltersState();

export default function filters(
  state: FiltersState = initialState,
  action: FiltersActions
): FiltersState {
  switch (action.type) {
    case FiltersActionTypes.DATA_SET:
      return state.setIn(action.payload.path, fromJS(action.payload.data));
    case FiltersActionTypes.CLEAR_DATA:
      return state.setIn(action.payload, Map());
    default:
      return state;
  }
}
