// import { combineReducers } from "redux";

// import clients from "./clients";
// import rightNavBar from './rightNavBar';

// export default combineReducers({ clients, rightNavBar });

import { connectRouter } from "connected-react-router/immutable";
import { History } from "history";
import { Map } from "immutable";
import { reducer as notifications } from "react-notification-system-redux";
import { combineReducers } from "redux-immutable";

import webSocket, { WebSocketState } from "src/reducers/WebSocket";

import rightNavBar, { RightNavBarState } from "src/reducers/RightNavBar";

import upsertServiceForm, {
  UpsertServiceFormState,
} from "src/reducers/UpsertServiceForm";
import modal, { ModalState } from "./Modal";
import filters, { FiltersState } from "./Filters";
import serviceTypes, { ServiceTypeState } from "./ServiceTypes";
import services, { ServicesState } from "./Services";
import clients from "./clients";
import dealerClients from "./Dealer/clients";
import customers from "./customers";
import domains from "./domains";
import messages from "./Messages/index";
import domainTypes from "./domianTypes";
import lawyerAppointments from "./LawyerAppointments";
import lawyers from "./lawyers";

import main, { MainState } from "./Main";

interface IRootReducer {
  modal: ModalState;
  services: ServicesState;
  serviceTypes: ServiceTypeState;
  filters: FiltersState;
  webSocket: WebSocketState;
  rightNavBar: RightNavBarState;
  upsertServiceForm: UpsertServiceFormState;
  main: MainState;
  dealerClients: any;
  lawyers: any;
}

export type AppState = Map<
  keyof IRootReducer,
  IRootReducer[keyof IRootReducer]
>;

const rootReducers = (history: History) =>
  combineReducers({
    modal,
    services,
    webSocket,
    rightNavBar,
    upsertServiceForm,
    serviceTypes,
    filters,
    clients,
    customers,
    domains,
    domainTypes,
    main,
    lawyerAppointments,
    messages,
    dealerClients,
    lawyers,
  });

export default rootReducers;
