import React from "react";
import PropTypes from "prop-types";
import styles from "./Confirmation.module.scss";
import Header from "../../../Shared/Header/Header";
import User from "../../../../services/User";
import Container from "@material-ui/core/Container";
import Footer from "../../../Shared/Footer/Footer";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import { Scrollbars } from "rc-scrollbars";

const Confirmation = () => (
  <>
    <Header logged={User.getUser() ? true : false} />
    <div id="pageContent">
      <Scrollbars
        renderTrackVertical={(props) => (
          <div {...props} className="track-vertical" />
        )}
        renderThumbVertical={(props) => (
          <div {...props} className="thumb-vertical" />
        )}
      >
        <Container maxWidth="lg">
          <div className={styles.Confirmation}>
            <div className={styles.subtitle}>Gratulacje!</div>
            <h1 className={styles.title}>
              Twoje konto <strong>zostało aktywowane</strong>
            </h1>
            <div className={styles.content}>
              Wkrótce skontaktujemy się z Tobą w celu przedstawienia szczegółów
              współpracy.
            </div>

            <Link to={"/"} className={styles.toHome}>
              <Button variant="contained" color="primary">
                Wróć do strony głównej
              </Button>
            </Link>
          </div>
        </Container>
        <Footer />
      </Scrollbars>
    </div>
  </>
);

Confirmation.propTypes = {};

Confirmation.defaultProps = {};

export default Confirmation;
