export enum MessagesActionTypes {
  TOGGLE_SERVICE_SELECTION_MODE = "MESSAGES/SERVICE_SELECTION_TOGGLE",
  TOGGLE_CLIENT_SELECTION_MODE = "MESSAGES/CLIENT_SELECTION_TOGGLE",
  TOGGLE_SERVICE_SELECTION = "MESSAGES/SERVICE_SELECT",
  TOGGLE_CLIENT_SELECTION = "MESSAGES/CLIENT_SELECT",
  CLEAR_SERVICE_SELECTIONS = "MESSAGES/SERVICE_SELECTIONS_CLEAR",
  CLEAR_CLIENT_SELECTIONS = "MESSAGES/CLIENT_SELECTIONS_CLEAR",
  TOGGLE_NEW_MESSAGE_CREATOR = "MESSAGES/NEW_MESSAGE_CREATOR_TOGGLE",
  SET_MESSAGES_LIST = "MESSAGES/MESSAGES_LIST_SET",
  SET_AUTOCOMPLETE_DATA = "MESSAGES/AUTOCOMPLETE_DATA_SET",
  SET_ACTIVE_MESSAGE_ID = "MESSAGES/ACTIVE_MESSAGE_ID_SET",
  SET_ACTIVE_MESSAGE_OBJ = "MESSAGES/ACTIVE_MESSAGE_ID_OBJ",
  SET_MESSAGE_DETAILS = "MESSAGES/MESSAGE_DETAILS_SET",
  SET_RECEIVERS_AUTOCOMPLETE_DATA = "MESSAGES/RECEIVERS_AUTOCOMPLETE_DATA_SET",
  SET_MESSAGE_TEMPLATES = "MESSAGES/MESSAGE_TEMPLATES_DATA_SET",
  SET_MESSAGE_ACTIVE_TEMPLATE = "SET_MESSAGE_ACTIVE_TEMPLATE",
  TRIGGER_RESET_LIST = "MESSAGES/RESET_LIST_TRIGGER",
  SET_NEW_MESSAGE_DATA = "MESSAGES/NEW_MESSAGE_DATA_SET",
  RESET_NEW_MESSAGE_DATA = "MESSAGES/NEW_MESSAGE_DATA_RESET",
  SET_ACTIVE_TAB = "MESSAGES/ACTIVE_TAB_SET",
  TOGGLE_MAIL_CONFIG = "MESSAGES/MAIL_CONFIG_TOGGLE",
  SET_MAIL_CONFIG = "MESSAGES/SET_MAIL_CONFIG",
  SET_MESSAGES_LIST_FILTERS = "MESSAGES/LIST_FILTERS_SET",
  SAVE_ATTACHMENTS_STATE = "MESSAGES/ATTACHMENTS_STATE_SAVE",
  SET_PAYMENT_DUE = "SET_PAYMENT_DUE",
  SET_PAYMENT_TYPE = "SET_PAYMENT_TYPE",
  SET_FREQUENCY_TYPE = "SET_FREQUENCY_TYPE",
  SET_MESSAGES_LIST_LOADING = "SET_MESSAGES_LIST_LOADING",
  SET_RECIEVERS = "SET_RECIEVERS",
}
