import { SET_LAWYERS, SET_LAWYER } from "../actions/types";

const initialState = {
  lawyersList: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_LAWYERS: {
      return {
        ...state,
        lawyersList: action.payload,
      };
    }
    case SET_LAWYER: {
      return {
        ...state,
        lawyer: action.payload,
      };
    }
    default:
      return state;
  }
}
