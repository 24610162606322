import { uniqBy, uniqueId } from 'lodash';
import { fromJS, List, Map, Record } from 'immutable';
import { ActionType } from 'typesafe-actions';
import { UpsertServiceFormActionTypes } from 'src/action-types/UpsertServiceForm';
import * as actions from 'src/actions/UpsertServiceForm';
import { getSelectorsValues, handleArrayData, resultOutput, setServiceFormData } from 'src/transformators/UpsertServiceForm';

import { ImmutableMap } from 'src/helpers/ImmutableHelpers';

import { ISelectClientValue, ISelectedBranch } from 'src/components/Lawyer/Services/SelectClientModal';
import { ISelectLawyerValue } from 'src/components/Secretary/Services/SelectClientModal';
// import {
//   IServiceAdditionalInfoForm,
//   IServiceAgreementForm,
//   IServiceConditionForm,
//   IServiceConsequenceForm,
//   IServiceDescriptionForm,
//   IServiceDocumentationForm,
//   IServiceStepForm
// } from 'lawpoint-common/typing/staff-panel';

export enum ServiceFormCheckboxEnum {
  'TAK' = 1,
  'NIE',
  'B/D',
}

export type UpsertServiceFormActions = ActionType<typeof actions>;

export interface IValidation {
  [key: string]: {
    [key: string]: boolean;
  };
}

export interface IServiceUser {
  role: string;
  first_name: string;
  last_name: string;
  phone: string;
  email: string;
  key: string;
  street: string | undefined;
  apartment: string | undefined;
  house_number: string | undefined;
  post_code: string | undefined;
  city: string | undefined;
  record_type: number;
  field_name?: string;
  description?: string;
  after_state?: string;
}

export interface IDataSaved {
  steps: boolean;
  subject: boolean;
  description: boolean;
  consequence: boolean;
  additionalInformation: boolean;
  condition: boolean;
  agreement: boolean;
  opponents: boolean;
  offices: boolean;
  documentation: boolean;
  witnesses: boolean;
  guardian: boolean;
}

export interface IGuardian {
  id: number;
  first_name: string;
  last_name: string;
}

export interface IUpsertServiceFormState {
  details: any;
  serviceId: number | null;
  guardianId: number | null;
  guardiansList: List<ImmutableMap<IGuardian>>;
  selectedBranch: ImmutableMap<ISelectedBranch>;
  selectedType: ImmutableMap<ISelectedBranch>;
  steps: List<ImmutableMap<any>>;
  subject: ImmutableMap<IServiceUser>;
  description: ImmutableMap<any>;
  loanAnalysis: ImmutableMap<any>;
  consequence: ImmutableMap<any>;
  additionalInformation: ImmutableMap<any>;
  condition: ImmutableMap<any>;
  agreement: ImmutableMap<any>;
  opponents: List<ImmutableMap<IServiceUser>>;
  offices: List<ImmutableMap<IServiceUser>>;
  documentation: ImmutableMap<any>;
  witnesses: List<ImmutableMap<IServiceUser>>;
  selectedClient: ImmutableMap<ISelectClientValue>;
  selectedLawyer: ImmutableMap<ISelectLawyerValue>;
  data_saved: ImmutableMap<IDataSaved>;
  serviceFormData: any;
  savedServiceFormData: any;
  prioritySelectors: any;
  validation: any;
  suggestions: any;
}

export type UpsertServiceFormState = Record<IUpsertServiceFormState>;

export const newUserObject = (uniqueKey: string, side: number) =>
  Map({
    side: side,
    role: '',
    name: '',
    surname: '',
    street: '',
    flatNumber: '',
    number: '',
    zipCode: '',
    city: '',
    mobile: '',
    email: '',
    key: uniqueKey,
    isNew: true,
    isDeleted: false,
  });

export const newDetailObject = Map({
  cards: List(),
  description: '',
  extraInformation: '',
  stateAfter: '',
  stateBefore: '',
});

const UpsertServiceFormState = Record<IUpsertServiceFormState>({
  serviceFormData: {},
  suggestions: {},
  savedServiceFormData: {},
  prioritySelectors: {
    select_1: {
      value: null,
      label: '',
    },
    select_2: {
      value: null,
      label: '',
    },
    select_3: {
      value: null,
      label: '',
    },
  },
  guardiansList: List(),
  steps: List(),
  subject: newUserObject(uniqueId(), 0),
  description: Map({
    description: '',
    case_type: '',
  }),
  consequence: Map({
    consequences: '',
  }),
  additionalInformation: Map({
    additional_information: '',
  }),
  condition: Map({
    before_state: '',
  }),
  agreement: Map({
    contract: '',
  }),
  opponents: List([newUserObject(uniqueId(), 1)]),
  offices: List([newUserObject(uniqueId(), 2)]),
  documentation: Map({
    description: '',
  }),
  witnesses: List([newUserObject(uniqueId(), 3)]),
  guardianId: null,
  selectedClient: Map(),
  selectedLawyer: Map(),
  details: Map({
    id: null,
    cards: List(),
    description: '',
    extraInformation: '',
    stateAfter: '',
    stateBefore: '',
  }),
  data_saved: Map({
    steps: false,
    subject: false,
    description: false,
    consequence: false,
    additionalInformation: false,
    condition: false,
    agreement: false,
    opponents: false,
    offices: false,
    documentation: false,
    witnesses: false,
    guardian: false,
  }),
  serviceId: null,
  selectedBranch: Map(),
  selectedType: Map(),
  validation: {},
});

export interface IEditServiceFormDetails {
  additional_information: string | null;
  before_state: string | null;
  category_id: number;
  consequences: string | null;
  description: string | null;
  loanAnalysis: string | null;
  guardian_id: number | null;
  service_id: number | null;
  people: List<ImmutableMap<IServiceUser>>;
  steps: List<ImmutableMap<any>>;
  type_id: number | null;
}

export enum RecordType {
  SUBJECT,
  ENEMY,
  OFFICE,
  WITNESS,
}

const initialState: UpsertServiceFormState = new UpsertServiceFormState();

export default function upsertServiceForm(
  state: UpsertServiceFormState = initialState,
  action: UpsertServiceFormActions
): UpsertServiceFormState {
  switch (action.type) {
    case UpsertServiceFormActionTypes.SET_FORM_DATA:
      return state.setIn(action.payload.path, action.payload.value);
    case UpsertServiceFormActionTypes.SET_SERVICE_FORM_DATA:
      return setServiceFormData(state, fromJS(action.payload));
    case UpsertServiceFormActionTypes.SET_CLIENT:
      return state.set('selectedClient', fromJS(action.payload));
    case UpsertServiceFormActionTypes.SET_LAWYER:
      return state.set('selectedLawyer', fromJS(action.payload));
    case UpsertServiceFormActionTypes.SET_SELECTED_CATEGORY:
      return state.set('selectedBranch', fromJS(action.payload));
    case UpsertServiceFormActionTypes.SET_SELECTED_TYPE:
      return state.set('selectedType', fromJS(action.payload));
    case UpsertServiceFormActionTypes.SET_PRIORITY_SELECTORS_DATA:
      return state.setIn(['prioritySelectors', action.payload.inputName], action.payload.select);
    case UpsertServiceFormActionTypes.SET_GUARDIAN:
      return state.set('guardianId', action.payload);
    case UpsertServiceFormActionTypes.SET_FORM_DATA_STATUS:
      return state.setIn(['data_saved', action.payload], true);
    case UpsertServiceFormActionTypes.SERVICE_FORM_DATA_SET:
      return state.setIn(['serviceFormData', ...action.payload.path], action.payload.value);
    case UpsertServiceFormActionTypes.SET_FORM_TAB_VALIDATION_DATA:
      return state.setIn(['validation', ...action.payload.path], action.payload.value);
    case UpsertServiceFormActionTypes.SET_FORM_ARRAY_DATA:
      return handleArrayData(state, action.payload, 'serviceFormData');
    case UpsertServiceFormActionTypes.SET_FORM_VALIDATION_DATA:
      return handleArrayData(state, action.payload, 'validation');
    case UpsertServiceFormActionTypes.SET_AUTOCOMPLETE_SUGGESTIONS:
      const { suggestions } = action.payload;
      return state.set('suggestions', uniqBy(suggestions, action.payload.data.name));
    case UpsertServiceFormActionTypes.AUTOCOMPLETE_PERSON_DATA:
      const { data, person } = action.payload;
      if (!person) {
        return state;
      }

      const flatPath = data.addToPath && !data.exactPath ? [data.fieldType, data.arrayIndex] : undefined;
      const nestedPath = data.exactPath ? [data.fieldType, data.parentArrayIndex, data.addToPath, data.arrayIndex] : [data.fieldType];
      const path = flatPath ? flatPath : nestedPath;
      delete person.id;
      return state.setIn(['serviceFormData', data.selectedTab, ...path], person);
    case UpsertServiceFormActionTypes.SET_FORM_SAVED_DATA:
      const parsed = { ...resultOutput(action.payload) };
      return state.withMutations((mutable) => {
        mutable
          .set('serviceFormData', parsed)
          .set('savedServiceFormData', parsed)
          .set('guardiansList', fromJS(action.payload.guardians))
          .setIn(['prioritySelectors', 'select_1'], getSelectorsValues(action.payload, 'select_1', state))
          .setIn(['prioritySelectors', 'select_2'], getSelectorsValues(action.payload, 'select_2', state))
          .setIn(['prioritySelectors', 'select_3'], getSelectorsValues(action.payload, 'select_3', state));
      });
    case UpsertServiceFormActionTypes.CLEAR_FORM_DATA:
      return initialState;
    case UpsertServiceFormActionTypes.REVERT_CHANGES:
      const prevData = state.getIn(['savedServiceFormData', action.payload.tab]);
      if (prevData) {
        return state.setIn(['serviceFormData', action.payload.tab], prevData);
      } else {
        return state.removeIn(['serviceFormData', action.payload.tab]);
      }
    default:
      return state;
  }
}
