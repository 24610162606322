export enum MainActionTypes {
  TOGGLE_CHAT = 'MAIN/CHAT_TOGGLE',
  TOGGLE_PAYMENT = 'MAIN/PAYMENT_TOGGLE',
  TOGGLE_VALUATION = 'MAIN/VALUATION_TOGGLE',
  TOGGLE_DOCUMENT_FORMATS_LIST = 'MAIN/DOCUMENT_FORMATS_LIST_TOGGLE',
  TOGGLE_DOCUMENTS = 'MAIN/DOCUMENTS_TOGGLE',
  TOGGLE_DOCUMENT_EDIT = 'MAIN/DOCUMENT_EDIT_TOGGLE',
  TOGGLE_ADDITIONAL_DOCUMENT_EDIT = 'MAIN/ADDITIONAL_DOCUMENT_EDIT_TOGGLE',
  TOGGLE_DOCUMENT_EDIT_MINIMALIZE = 'MAIN/DOCUMENT_EDIT_MINIMALIZE_TOGGLE',
  TOGGLE_ADDITIONAL_DOCUMENT_EDIT_MINIMALIZE = 'MAIN/ADDITIONAL_DOCUMENT_EDIT_MINIMALIZE_TOGGLE',
  TOGGLE_SECRETARY_CHAT = 'MAIN/SECRETARY_CHAT_TOGGLE',
  TOGGLE_ADD_CLIENT_FORM = 'MAIN/ADD_CLIENT_FORM_TOGGLE',
  TOGGLE_ADD_SERVICE_FORM = 'MAIN/ADD_SERVICE_FORM_TOGGLE',
  TOGGLE_CELL_PHONE = 'MAIN/CELL_PHONE_TOGGLE',
  HIDE_ADDITIONAL_COMPONENTS = 'MAIN/ADDITIONAL_COMPONENTS_HIDE',
  TOGGLE_SWITCH_LAWYER_VIEW = 'MAIN/SWITCH_LAWYER_VIEW_TOGGLE',
  CLEAR_DATA = 'MAIN/CLEAR_DATA',
  SET_CURRENT_LIST_LENGTH = 'MAIN/CURRENT_LIST_LENGTH',
  TOGGLE_BLOCKED = 'MAIN/BLOCKED_TOGGLE',
  SET_CURRENT_PAGE = 'MAIN/CURRENT_PAGE',
  SET_USER_ID = 'SET_USER_ID',
  SET_USER = 'SET_USER',
  SET_NOTIFICATION = 'SET_NOTIFICATION',
  SET_SHOW_CURRENT_USER_SERVICES = 'SET_SHOW_CURRENT_USER_SERVICES',
  SET_SHOW_CURRENT_USER_CALLS = 'SET_SHOW_CURRENT_USER_CALLS',
  SET_SHOW_CURRENT_USER_MESSAGES = 'SET_SHOW_CURRENT_USER_MESSAGES',
}
