import { AppState } from "src/reducers";
import { diff } from "deep-object-diff";
import { isEmpty } from "lodash";

export const getServiceFormData = (state: AppState) => {
  return state.get("upsertServiceForm");
};
export const getServiceId = (state: AppState) =>
  state.getIn(["upsertServiceForm", "serviceId"]);

export const getSteps = (state: AppState) =>
  state.getIn(["upsertServiceForm", "steps"]).sort((valueA, valueB) => {
    if (valueA.get("date") > valueB.get("date")) {
      return -1;
    }
    if (valueA.get("date") < valueB.get("date")) {
      return 1;
    }
    return 0;
  });

export const getSubject = (state: AppState) =>
  state.getIn(["upsertServiceForm", "subject"]);

export const getDescription = (state: AppState) =>
  state.getIn(["upsertServiceForm", "description"]);

export const getOpponents = (state: AppState) =>
  state.getIn(["upsertServiceForm", "opponents"]);

export const getOffices = (state: AppState) =>
  state.getIn(["upsertServiceForm", "offices"]);

export const getConsequence = (state: AppState) =>
  state.getIn(["upsertServiceForm", "consequence"]);

export const getCondition = (state: AppState) =>
  state.getIn(["upsertServiceForm", "condition"]);

export const getAdditionalInformation = (state: AppState) =>
  state.getIn(["upsertServiceForm", "additionalInformation"]);

export const getGuardian = (state: AppState) =>
  state.getIn(["upsertServiceForm", "guardian"]);

export const getWitnesses = (state: AppState) =>
  state.getIn(["upsertServiceForm", "witnesses"]);

export const getDocumentation = (state: AppState) =>
  state.getIn(["upsertServiceForm", "documentation"]);

export const getAgreement = (state: AppState) =>
  state.getIn(["upsertServiceForm", "agreement"]);

export const getSelectedClient = (state: AppState) =>
  state.getIn(["upsertServiceForm", "selectedClient"]);
export const getGuardianId = (state: AppState) =>
  state.getIn(["upsertServiceForm", "guardianId"]);

export const getSelectedBranch = (state: AppState) =>
  state.getIn(["upsertServiceForm", "selectedBranch"]);

export const getSelectedType = (state: AppState) =>
  state.getIn(["upsertServiceForm", "selectedType"]);

export const getServiceDataState = (state: AppState, form: string) =>
  state.getIn(["upsertServiceForm", "data_saved", form]);
export const getCurrentServiceFormInputValue = (
  state: AppState,
  path: Array<number | string>
) => state.getIn(["upsertServiceForm", "serviceFormData", ...path]);

export const getCurrentSuggestions = (state: AppState) =>
  state.getIn(["upsertServiceForm", "suggestions"]);

export const getPrioritySelectValue = (state: AppState, selectName: string) =>
  state.getIn(["upsertServiceForm", "prioritySelectors", selectName]);

export const getPrioritySelectors = (state: AppState) =>
  state.getIn(["upsertServiceForm", "prioritySelectors"]);

export const getCurrentRowData = (state: AppState, path: string[]) =>
  state.getIn(["upsertServiceForm", "serviceFormData", ...path]);

export const getCurrentTabData = (state: AppState, tab: string) =>
  state.getIn(["upsertServiceForm", "serviceFormData", tab]);

export const getSavedServiceForm = (state: AppState) =>
  state.getIn(["upsertServiceForm", "savedServiceFormData"]);

export const getHasDiff = (state: AppState, tab: string) => {
  const prevData = state.getIn([
    "upsertServiceForm",
    "savedServiceFormData",
    tab,
  ]);
  const currentData = state.getIn([
    "upsertServiceForm",
    "serviceFormData",
    tab,
  ]);
  return !isEmpty(diff(prevData, currentData));
};

export const getGuardiansList = (state: AppState) =>
  state.getIn(["upsertServiceForm", "guardiansList"]);

export const getServiceFormValidation = (state: AppState, tab: string) =>
  state.getIn(["upsertServiceForm", "validation", tab]);

export const getInitialChildLength = (state: AppState, path: string[]) => {
  const array = state.getIn(["upsertServiceForm", "serviceFormData", ...path]);
  return array ? array.length : 0;
};
