import {
  SET_CLIENTS,
  SET_CLIENTS_LIST_LOADING,
  SET_CLIENT_DETAILS,
  SET_CLIENT_DETAILS_LOADING,
  SET_CLIENT_ID,
  SET_CLIENT_EDIT,
  SET_CLIENT_CREATE,
  SET_CLIENT_UPDATE_SUCCESSFUL,
  SET_DEALERS,
} from "../actions/types";

const initialState = {
  clientList: [],
  clientListLoading: false,
  clientDetailsLoading: false,
  clientDetails: null,
  clientCount: null,
  clientActiveId: null,
  clientEdit: false,
  clientUpdateSuccessful: false,
  dealerList: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CLIENTS: {
      const { clientList } = action.payload;
      return {
        ...state,
        clientList: clientList,
        clientCount: clientList.length,
      };
    }
    case SET_CLIENTS_LIST_LOADING: {
      return {
        ...state,
        clientListLoading: action.payload,
      };
    }
    case SET_CLIENT_DETAILS: {
      const { clientDetails } = action.payload;
      return {
        ...state,
        clientDetails: clientDetails,
      };
    }
    case SET_CLIENT_DETAILS_LOADING: {
      return {
        ...state,
        clientDetailsLoading: action.payload,
      };
    }
    case SET_CLIENT_ID: {
      return {
        ...state,
        clientActiveId: action.payload,
      };
    }
    case SET_CLIENT_EDIT: {
      return {
        ...state,
        clientEdit: action.payload,
      };
    }
    case SET_CLIENT_CREATE: {
      return {
        ...state,
        clientCreate: action.payload,
      };
    }
    case SET_CLIENT_UPDATE_SUCCESSFUL: {
      return {
        ...state,
        clientUpdateSuccessful: action.payload,
      };
    }
    case SET_DEALERS: {
      const { dealerList } = action.payload;
      return {
        ...state,
        dealerList: dealerList,
        //dealerCount: dealerList.length
      };
    }
    default:
      return state;
  }
}
