export enum ServicesActionTypes {
  SERVICE_ID_SET = "SERVICES/SET_ID",
  SERVICES_LIST_SET = "SERVICES/SET_LIST",
  ADD_SERVICE = "SERVICES/ADD_SERVICE",
  ADD_SERVICE_SUCCESS = "SERVICES/ADD_SERVICE_SUCCESS",
  SERVICE_DETAILS_SET = "SERVICES/DETAILS_SET",
  LINK_SERVICES = "SERVICES/LINK_SERVICES",
  SERVICE_STEPS_SET = "SERVICES/STEPS_SET",
  EDIT_SERVICE = "SERVICES/EDIT_SERVICE",
  SET_SERVICE_LIST_LOADING = "SET_SERVICE_LIST_LOADING",
  SET_SERVICE_UPDATE_SUCCESSFUL = "SET_SERVICE_UPDATE_SUCCESSFUL",
  CHANGE_ACTIVE_TAB = "CHANGE_ACTIVE_TAB",
}
