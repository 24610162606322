import { Record } from 'immutable';
import { ActionType } from 'typesafe-actions';
import { Map } from 'immutable';

import { MainActionTypes } from 'src/action-types/Main';
import * as actions from 'src/actions/Main';

export type MainActions = ActionType<typeof actions>;

export interface IMainState {
  isChatShown: boolean;
  isPaymentShown: boolean;
  isValuationShown: boolean;
  isDocumentFormatsListShown: boolean;
  isDocumentListShown: boolean;
  isDocumentEditShown: boolean;
  isAdditionalDocumentEditShown: boolean;
  isDocumentEditMinimalized: boolean;
  isAdditionalDocumentEditMinimalized: boolean;
  isSecretaryChatShown: boolean;
  isAddClientFormShown: boolean;
  isCellPhoneShown: boolean;
  isSwitchLawyerViewShown: boolean;
  showCurrentUserServices: boolean;
  showCurrentUserCalls: boolean;
  showCurrentUserMessages: boolean;
  currentListLength: number;
  isBlocked: boolean;
  currentPage: Map<string, number>;
  userId: number | null;
  notification: INotification | null;
  user: any;
}

interface INotification {
  type: string;
  text: string;
  key: string;
}

export type MainState = Record<IMainState>;

const MainState = Record<IMainState>({
  isChatShown: false,
  isPaymentShown: false,
  isValuationShown: false,
  isDocumentFormatsListShown: false,
  isDocumentListShown: false,
  isDocumentEditShown: false,
  isAdditionalDocumentEditShown: false,
  isDocumentEditMinimalized: false,
  isAdditionalDocumentEditMinimalized: false,
  isSecretaryChatShown: false,
  isAddClientFormShown: false,
  isCellPhoneShown: false,
  isSwitchLawyerViewShown: false,
  currentListLength: 0,
  isBlocked: false,
  currentPage: Map(),
  userId: null,
  notification: null,
  user: null,
  showCurrentUserServices: true,
  showCurrentUserCalls: true,
  showCurrentUserMessages: true,
});

const initialState: MainState = new MainState();

export default function main(
  state: MainState = initialState,
  action: MainActions
): MainState {
  switch (action.type) {
    case MainActionTypes.TOGGLE_CHAT:
      return state.set('isChatShown', action.payload);
    case MainActionTypes.TOGGLE_PAYMENT:
      return state.set('isPaymentShown', action.payload);
    case MainActionTypes.TOGGLE_VALUATION:
      return state.set('isValuationShown', action.payload);
    case MainActionTypes.TOGGLE_DOCUMENT_FORMATS_LIST:
      return state.set('isDocumentFormatsListShown', action.payload);
    case MainActionTypes.TOGGLE_DOCUMENTS:
      return state.set('isDocumentListShown', action.payload);
    case MainActionTypes.TOGGLE_DOCUMENT_EDIT:
      return state.set('isDocumentEditShown', action.payload);
    case MainActionTypes.TOGGLE_ADDITIONAL_DOCUMENT_EDIT:
      return state.set('isAdditionalDocumentEditShown', action.payload);
    case MainActionTypes.TOGGLE_DOCUMENT_EDIT_MINIMALIZE:
      return state.set(
        'isDocumentEditMinimalized',
        !state.get('isDocumentEditMinimalized')
      );
    case MainActionTypes.TOGGLE_ADDITIONAL_DOCUMENT_EDIT_MINIMALIZE:
      return state.set(
        'isAdditionalDocumentEditMinimalized',
        !state.get('isAdditionalDocumentEditMinimalized')
      );
    case MainActionTypes.TOGGLE_SECRETARY_CHAT:
      return state.set('isSecretaryChatShown', action.payload);
    case MainActionTypes.TOGGLE_ADD_CLIENT_FORM:
      return state.set('isAddClientFormShown', action.payload);
    case MainActionTypes.TOGGLE_CELL_PHONE:
      return state.set('isCellPhoneShown', action.payload);
    case MainActionTypes.TOGGLE_SWITCH_LAWYER_VIEW:
      return state.set('isSwitchLawyerViewShown', action.payload);
    case MainActionTypes.SET_USER_ID:
      return state.set('userId', action.payload);
    case MainActionTypes.SET_USER:
      return state.set('user', action.payload);
    case MainActionTypes.HIDE_ADDITIONAL_COMPONENTS:
      return state.withMutations((mutable) => {
        mutable
          .set('isSwitchLawyerViewShown', false)
          .set('isCellPhoneShown', false);
      });
    case MainActionTypes.SET_CURRENT_LIST_LENGTH:
      return state.set('currentListLength', action.payload);
    case MainActionTypes.SET_CURRENT_PAGE:
      const { tableName, value } = action.payload;
      return state.set(
        'currentPage',
        state.get('currentPage').set(tableName, value)
      );
    case MainActionTypes.CLEAR_DATA:
      return initialState.withMutations((mutable) => {
        mutable
          .set('currentListLength', state.get('currentListLength'))
          .set('isAddClientFormShown', state.get('isAddClientFormShown'))
          .set('isDocumentEditShown', state.get('isDocumentEditShown'));
      });
    case MainActionTypes.TOGGLE_BLOCKED:
      return state.set('isBlocked', action.payload);
    case MainActionTypes.SET_NOTIFICATION:
      return state.set('notification', action.payload);
    case MainActionTypes.SET_SHOW_CURRENT_USER_SERVICES:
      return state.set('showCurrentUserServices', action.payload);
    case MainActionTypes.SET_SHOW_CURRENT_USER_CALLS:
      return state.set('showCurrentUserCalls', action.payload);
    case MainActionTypes.SET_SHOW_CURRENT_USER_MESSAGES:
      return state.set('showCurrentUserMessages', action.payload);
    default:
      return state;
  }
}
