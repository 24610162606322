import axios from "axios";
import User from "./User";

let Api = null;

function setToken() {
  let token = localStorage.getItem("token")
    ? localStorage.getItem("token")
    : null;
  if (token) {
    if (Api) Api.defaults.headers.common["Authorization"] = "Bearer " + token;
    else axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  }
}

setToken();

Api = axios.create({
  baseURL: process.env.REACT_APP_WIDEOPRAWNIK_API,
});

Api.interceptors.response.use(null, function (error) {
  if (error.response.status === 403) {
    User.logout();
    return false;
  }
  return Promise.reject(error);
});

export default Api;
export { setToken };
