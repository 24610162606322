import i18n from "i18next";
import { reactI18nextModule } from "react-i18next";
import { POLISH } from "src/helpers/Translations/languages";
import resources from "src/helpers/Translations";

// tslint:disable-next-line:no-floating-promises
i18n.use(reactI18nextModule).init({
  resources,
  lng: POLISH,
  fallbackLng: POLISH,
  debug: false,
  react: {
    wait: true,
  },
});
