export const pl = {
  translation: {
    app: {
      common: {
        branches: {
          buttonText: 'Wybierz',
          listPosition: 'Miejsce na liście',
          active: 'Aktywna',
          hidden: 'Ukryta',
          branch: 'Dziedzina prawa',
          description: 'Opis',
          attachFile: 'Załącz zdjęcie (PNG)',
          changeFile: 'Zmień zdjęcie (PNG)',
          addLawyer: '+ Dodaj prawnika',
          save: 'Zapisz',
          cancel: 'Anuluj',
          lawyer: {
            listPosition: 'Miejsce prawnika na liście',
            lawyer: 'Prawnik',
            hidden: 'Ukryty',
          },
        },
        employees: {
          provisionTable: {
            level: 'Poziom',
          },
          sellerForm: {
            networkLabel: 'Sieć',
            positionLabel: 'Stanowisko',
            introductorLabel: 'Wprowadzaj',
            provisionLabel: 'Prowizja',
            disablePromoteLabel: 'Blokada awansów',
            monthlyPayLabel: 'Wynagrodzenie mies.',
            bonusLabel: 'Premia',
            positions: [
              'Asystent',
              'Konsultant',
              'Doradca Pr',
              'Starszy Doradca Pr',
              'Expert',
              'Kierownik Z',
              'Dyrektor R',
              'Dyrektor S',
              'Dyrektor G',
              'V-prezes S',
            ],
            provisions: ['ręcznie', 'automatycznie'],
          },
          lawyerForm: {
            monthlyPayLabel: 'Wynagrodzenie mies.',
            bonusLabel: 'Premia',
            provisionLabel: 'Prowizja',
            descriptionPlaceholder: 'Opis',
            positionLabel: 'Stanowisko',
            phone: 'Telefon',
            positions: [
              'Asystent Prawny',
              'Młodszy Prawnik',
              'Prawnik',
              'Starszy Prawnik',
              'Partner',
              'Starszy Partner',
              'Partner Zarządzający',
              'Wspólnik',
            ],
          },
          basicInfoForm: {
            header: 'Dane podstawowe',
            firstName: 'Imie',
            lastName: 'Nazwisko',
            pesel: 'Pesel',
            idNumber: 'Number dowodu',
            adress: 'Adres zameldowania',
            otherCorrespondenceAdress: 'Inny adres koresponcencyjny',
          },
          contactForm: {
            email: 'E-mail',
            phoneNumber: 'Numer telefonu',
          },
          mainForm: {
            edit: 'Edycja',
            save: 'Zapisz',
            contact: 'Kontakt',
            next: 'Dalej',
            cancel: 'Anuluj',
            back: 'Wróć',
            newEmployee: 'Nowy pracownik',
            bankAccount: 'Rachunek bankowy',
          },
          companyForm: {
            header: 'Reprezentując firmę',
            companyName: 'Nazwa firmy',
            shortCompanyName: 'Skrócona nazwa firmy',
            companyAdress: 'Adres firmy',
            otherCorrespondenceAdress: 'Inny adres koresponcencyjny',
            krs: 'KRS',
            nip: 'NIP',
            regon: 'Regon',
          },
          adressForm: {
            street: 'Ulica',
            houseNumber: 'Nr domu',
            apartmentNumber: 'Nr lokalu',
            city: 'Miejscowość',
            zipCode: '00-000',
          },
        },
        documentEdit: {
          acceptButton: 'Zatwierdź',
          saveButton: 'Zapisz',
          cancelButton: 'Anuluj',
          dontSaveButton: 'Odrzuć',
          savePopUpText: 'Zapisać dokument?',
          savePopUpText2: 'Zapisać wszystkie dokumenty?',
          success: 'Pomyślnie zapisano pismo',
          customizedTemplateHeader: 'Wprowadź nazwę dla dokumentu:',
        },
        serviceSteps: {
          option1: 'Etap',
          option2: 'Notatka',
          option3: 'Pismo',
          option4: 'Monit',
          option5: 'Rozprawa',
          option6: 'Dokumenty',
          option7: 'Ugoda',
          option8: 'Termin',
          option9: 'Płatność',
          option10: 'Konsultacja',
          option11: 'Inne',
          option12: 'Zlecenie zakończone',
        },
        clientDetails: {
          nip: 'NIP:',
        },
        serviceCalls: {
          currency: 'zł',
        },
        serviceCards: {
          title0: 'Podmiot zlecenia',
          title1: 'Przeciwnik',
          title2: 'Urząd',
          title3: 'Świadek',
          title4: 'Urząd',
        },
        dynamicServiceCards: {
          subject: 'Podmiot zlecenia',
          matter: 'Przedmiot zlecenia',
          perpetrator: 'Sprawca',
          insurer: 'Ubezpieczyciel',
          responsible: 'Odpowiedzialny',
          proxy: 'Pełnomocnik',
          department: 'Urząd',
          witnesses: 'Świadek',
        },
        pagination: {
          previousText: 'Poprzednia',
          nextText: 'Następna',
          loadingText: 'Ładowanie...',
          noDataText: 'Brak danych',
          pageText: 'Strona',
          ofText: 'z',
          rowsText: 'wierszy',
        },
        clientForm: {
          passwordPopUp: {
            yes: 'Tak',
            no: 'Nie',
            longQuestion: 'Czy na pewno chcesz zmienić hasło klientowi nr',
            passwordChange: 'Zmiana hasła',
          },
          confirmModal: {
            info: 'Czy zapisać dane klienta?',
          },
          header: {
            clientNew: 'Nowy klient',
            clientNumber: 'Nr klienta',
            clientPassword: 'Generuj Klientowi nowe hasło',
          },
          contact: {
            emailPlaceholder: 'E-mail',
            phonePlaceholder: 'Numer telefonu',
          },
          client: {
            namePlaceholder: 'Imię',
            lastNamePlaceholder: 'Nazwisko',
            peselPlaceholder: 'Pesel',
            idPlaceholder: 'Nr dowodu',
          },
          minor: {
            namePlaceholder: 'Imię',
            lastNamePlaceholder: 'Nazwisko',
            peselPlaceholder: 'Pesel',
            idPlaceholder: 'Nr dowodu',
          },
          baseAddress: {
            streetPlaceholder: 'Ulica',
            houseNumberPlaceholder: 'Nr domu',
            apartmentNumberPlaceholder: 'Nr lokalu',
            cityPlaceholder: 'Miejscowość',
            postCodePlaceholder: '00-000',
          },
          company: {
            namePlaceholder: 'Nazwa firmy',
            shortNamePlaceholder: 'Skrócona nazwa firmy',
            nipPlaceholder: 'NIP',
            regonPlaceholder: 'Regon',
            krsPlaceholder: 'KRS',
          },
          other: {
            saveButton: 'Zapisz',
            startCounting: 'Zacznij naliczać',
            editButton: 'Edytuj',
            cancelButton: 'Anuluj',
            contentPlaceholder: 'Treść',
            commandPlaceholder: 'Numer polecenia',
            saveSuccess: 'Dane zostały zapisane',
            updateSuccess: 'Dane zostały zaktualizowane',
            passwordGenerateSuccess: 'Pomyślnie wygenerowano hasło',
            passwordGenerateFailure: 'Nie udało się wygenerować hasła',
            titles: {
              title1: 'Kontakt',
              title2: 'Dane podstawowe',
              title3: 'Adres zameldowania',
              title4: 'Inny adres korespondencyjny',
              title5: 'Reprezentując firmę',
              title9: 'Adres firmy',
              title6: 'Dodatkowe informacje',
              title7: 'Rekomendacje',
              title8: 'Adres korespondencyjny firmy',
              title10: 'Reprezentując małoletniego lub ubezwłasnowolnionego całkowicie',
            },
            saveAll: 'Zapisz całość',
            closeForm: 'Zamknij formularz',
          },
        },
        documentEditor: {
          colorPicker: {
            initialColor: 'Domyślny kolor',
          },
          attachments: {
            attachments: 'Załączniki',
          },
        },
        upsertServiceForm: {
          confirmModal: {
            topText: 'Przełączenie formularza',
            options: {
              yes: 'TAK',
              no: 'NIE',
            },
            steps: 'Czy chcesz zapisać wprowadzone kroki?',
            subject: 'Czy chcesz zapisać szczegóły przedmiotu zlecenia?',
            description: 'Czy chcesz zapisać opis sprawy?',
            opponents: 'Czy chcesz zapisać wprowadzonych przeciwników?',
            offices: 'Czy chcesz zapisać wprowadzone urzędy?',
            consequence: 'Czy chcesz zapisać konsekwencje zdarzenia?',
            condition: 'Czy chcesz zapisać stan przed zdarzeniem?',
            additionalInformation: 'Czy chcesz zapisać dodatkowe informacje?',
            guardian: 'Czy chcesz zapisać nowego opekuna?',
            witnesses: 'Czy chcesz zapisać wprowadzonych świadków?',
            changes: 'Czy chcesz zapisać wprowadzone zmiany?',
          },
          documentationTab: {
            incoming: '+ Przychodzące',
            documents: '+ Dokumenty',
          },
          modal: {
            lawyer: 'Wybierz prawnika',
            info: 'Wybierz klienta',
            branch: 'Wybierz dziedzinę prawa',
            domainType: 'Wybierz rodzaj',
            button: 'Akceptuję',
          },
          steps: {
            header: 'Działania',
            placeholder: 'Treść',
            pricePlaceholder: 'Kwota',
            deadlinePlaceholder: 'Termin',
            addButton: '+ Dodaj',
            removeButton: 'x',
          },
          loanForm: {
            addIncrease: '+ podwyższenie kapitału',
            addTranches: '+ transze',
            durationChanges: '+ zmiana okresu spłaty',
            excessPayment: '+ nadpłata',
            earlyPayment: '+ wcześniejsza spłata',
            noSpread: '+ data od której nie ma spreadu',
            confirmPayment: '+ suma spłat z zaświadczenia',
            bankMarginChanges: '+ zmiana marży',
          },
          subject: {
            header: 'Podmiot zlecenia',
          },
          description: {
            header: 'Przedmiot zlecenia',
          },
          opponents: {
            header: 'Przeciwnicy',
          },
          offices: {
            header: 'Urzędy',
          },
          consequence: {
            header: 'Konsekwencje',
          },
          condition: {
            header: 'Stan przed zdarzeniem',
          },
          additionalInformation: {
            header: 'Dodatkowe informacje',
          },
          guardian: {
            header: 'Opiekun',
          },
          witnesses: {
            header: 'Świadkowie',
          },
          documentation: {
            header: 'Dokumentacja',
          },
          agreement: {
            header: 'Umowa',
          },
          other: {
            button: 'Zapisz',
          },
          dynamicServiceForm: {
            headers: {
              actions: 'Działania',
              person: 'Podmiot zlecenia',
              description: 'Przedmiot zlecenia',
              enemy: 'Przeciwnicy',
              offices: 'Urzędy',
              consequences: 'Konsekwencje',
              condition: 'Relacje',
              witnesses: 'Świadkowie',
              additional: 'Dodatkowe informacje',
              documentation: 'Dokumentacja',
              agreement: 'Umowa',
              guardian: 'Opiekun',
            },
            dynamicHeaders: [
              {},
              {
                '1': 'Zmarły',
                '2': 'Poszkodowany',
              },
            ],
            buttons: {
              addButton: '+ Dodaj',
              addContactButton: '+ Dodaj osobe do kontaktu',
            },
          },
        },
      },
      timetable: {
        viewer: {
          adminHeader: {
            lp: 'LP',
            branch: 'Dzienina',
            lawyer: 'Prawnik',
            date: 'Data',
            hour: 'Godzina',
          },
          header: {
            previousWeek: 'Poprzedni tydzień',
            nextWeek: 'Następny tydzień',
          },
          shortWeekNames: 'Nie,Pon,Wt,Śr,Czw,Pt,Sob',
        },
        details: {
          workHoursHeader: 'Godz. pracy',
          unavailableHoursHeader: 'Niedostępny',
          mondayHeader: 'Poniedziałek',
          tuesdayHeader: 'Wtorek',
          wednesdayHeader: 'Środa',
          thursdayHeader: 'Czwartek',
          fridayHeader: 'Piątek',
          controls: {
            save: 'Zapisz',
            cancel: 'Anuluj',
          },
          event: {
            hourPrefix: 'godz.',
            calls: 'Wezwanie',
            role1: 'świadek',
            role2: 'powód',
            received: {
              yes: 'odebrane',
              no: 'nieodebrane',
            },
          },
        },
      },
      employees: {
        list: {
          tabs: {
            tab1: 'Wszyscy',
            tab2: 'Prawnicy',
            tab3: 'Sprzedaż',
            tab4: 'Sekretariat',
            tab5: 'Pozostałe',
          },
        },
      },
      messages: {
        listHeader: {
          tabs: {
            received: 'Odebrane',
            sent: 'Wysłane',
            draft: 'Robocze',
            trash: 'Kosz',
          },
          controls: {
            selectAll: 'Zaznacz wszystko',
            selectionCounter: 'zaznaczono:',
            delete: 'Usuń zaznaczone',
            settings: 'Ustawienia',
          },
        },
        viewMessage: {
          from: 'Od:',
          topic: 'Temat:',
          to: 'Do:',
          reply: 'Odpowiedz',
          forward: 'Przekaż',
          print: 'Drukuj',
          save: 'Zapisz',
          delete: 'Usuń',
          attachments: 'Załączniki',
        },
        header: 'Nowa wiadomość',
        messageHeaders: {
          to: 'Do',
          subject: 'Temat',
        },
        messageBody: {
          messageContent: 'Treść wiadomości',
          valuationContent:
            'Przykładowa treść:    Szanowny Panie Piotrze, w nawiązaniu do ustaleń poczynionych w trakcie przeprowadzonej wideokonsultacji, poniej przysłam wycenę otrzymanego od Pana zlecenia',
        },
        messageControls: {
          send: 'Wyślij',
          attachFile: 'Załącz plik',
          save: 'Zapisz w roboczych',
          draftSave: 'Zapisz',
          cancel: 'Anuluj',
          delete: 'Usuń',
        },
        linkingFooter: {
          select: 'Wybierz',
          cancel: 'Anuluj',
          selected: 'Zaznaczono',
        },
        mailConfig: {
          header: 'Ustawienia poczty',
          mailFooter: 'Sygnaturka',
          redirect: 'Przekierowanie',
          autoResponse: {
            header: 'Automatyczna wiadomość zwrotna',
            dateFrom: 'Data od',
            dateTo: 'Data do',
            replyFrequency: {
              header: 'Częstotliwość odpowiedzi',
              option0: 'za każdym razem',
            },
            subject: 'Temat',
            message: 'Treść',
          },
          saveButton: 'Zapisz',
          cancelButton: 'Anuluj',
        },
      },
      documentRepository: {
        serviceStatus: {
          withoutDescription: 'Brak opisu',
          inputText: 'Wpisz treść',
          errrorRequired: 'To pole jest wymagane',
        },
        tabs: {
          // tab1: 'Przychodzące',
          // tab4: 'Wychodzące',
          // tab2: 'Dokumentacja',
          // tab3: 'Robocze',
          // emptyTab: 'Brak dokumentów do wyświetlenia'
          tab0: 'Przychodzące',
          tab1: 'Wychodzące',
          tab2: 'Dokumentacja',
          tab3: 'Robocze',
          emptyTab: 'Brak dokumentów do wyświetlenia',
        },
        tabs1: {
          tab1: 'Przychodzące',
          tab2: 'Dokumentacja',
          tab3: 'Robocze',
          tab4: 'Wychodzące',
          emptyTab: 'Brak dokumentów do wyświetlenia',
        },
      },
      callsHistory: {
        durationZero: 'nieodebrane',
        tabs: {
          tab1: 'Wszystkie',
          tab2: 'Wychodzące',
          tab3: 'Przychodzące',
          tab4: 'Nieodebrane',
          emptyTab: 'Brak dokumentów do wyświetlenia',
        },
        noData: {
          list: {
            header: 'Brak połączeń',
            content: 'Lista połączeń będzie widoczna po przeprowadzonej rozmowie z prawnikiem.',
          },
          details: {
            header: 'Brak informacji o połączeniu',
            content: 'Nie udało się pobrać szczegółów połączeń.',
          },
        },
        cellPhone: {
          placeholder: 'Wprowadź numer telefonu',
        },
      },
      emailHistory: {
        noData: {
          list: {
            header: 'Brak wiadomości',
            content: 'Lista wiadomości będzie widoczna po napisaniu jakieś wiadomości.',
          },
          details: {
            header: 'Brak informacji o połączeniu',
            content: 'Nie udało się pobrać szczegółów połączeń.',
          },
        },
        tabs: {
          tab1: 'Wszystkie',
          tab2: 'Odebrane',
          tab3: 'Wysłane',
          tab4: 'Kosz',
        },
        emailDetails: {
          button1: 'Odpowiedz',
          button2: 'Akceptuję',
          button3: 'Odrzucam',
          button4: 'Usuń',
          title: 'Załączniki',
        },
        attachment: {
          button: 'Wyświetl',
        },
        lawyer: {
          title: 'Połącz z prawnikiem',
          button: 'Połącz',
        },
      },
      panelReview: {
        confirmModal: {
          topText: 'Opuszczanie przeglądu',
          bottomText: 'Co chcesz zrobić?',
          options: {
            interrupt: 'PRZERWIJ PRZEGLĄD',
            end: 'ZAKOŃCZ PRZEGLĄD',
            cancel: 'ANULUJ',
          },
        },
        control: {
          title: 'Przegląd panelu',
          interrupt: 'przerwij przegląd panelu',
          end: 'zakończ przegląd panelu',
          placeholder: 'Wpisz wartość',
        },
      },
      services: {
        noData: {
          list: {
            header: 'Brak usług',
            content: 'Lista usług będzie widoczna po utworzeniu usługi.',
          },
          details: {
            header: 'Brak informacji o usłudze',
            content: 'Nie udało się pobrać szczegółów usługi.',
          },
        },
        relations: {
          harmed: 'Poszkodowany',
          dead: 'Zmarły',
          commonCompany: 'WSP Gospodarstwo',
          commonHouse: 'WSP Zamieszkanie',
        },
        tabs: {
          tab1: 'Przychodzące',
          tab2: 'Wychodzące',
          tab3: 'Dokumentacja',
          tab4: 'Robocze',
        },
        messageList: {
          title: 'Historia wiadomości w ramach usług',
        },
        states: {
          state1: 'Aktywna',
          state2: 'Analiza',
          state3: 'Zakończona',
          state4: 'Zawieszona',
        },
        serviceDetails: {
          button1: 'Wycena do akceptacji',
          button2: 'Gotowe zlecenie',
          button3: 'Załączone dokumenty',
        },
        stages: {
          title: 'Etapy:',
          button1: 'Rozwiń',
          button2: 'Ukryj',
        },
        serviceCalls: {
          title: 'Historia połączeń w ramach usługi',
          call: {
            button1: 'Zobacz grafik',
            states: {
              state1: 'zajęty',
              state2: 'niedostępny',
              state3: 'Połącz',
            },
          },
        },
      },
      panel: {
        detailsHeader: {
          print: 'Drukuj',
          resume: 'Wznów',
        },
      },
      settlements: {
        noData: {
          list: {
            header: 'Brak rozliczeń',
            content: 'Lista rozliczeń będzie widoczna po stworzeniu rachunku.',
          },
          details: {
            header: 'Brak informacji o połączeniu',
            content: 'Nie udało się pobrać szczegółów połączeń.',
          },
        },
        tabs: {
          tab1: 'Wszystkie',
          tab2: 'Faktury',
        },
        packetsContainer: {
          packet: {
            info: 'zł brutto',
            button: 'Wybierz',
          },
          packetInput: {
            inputPlaceholder: 'Wpisz kwotę',
            info: 'Doładuj konto dowolną kwotą (min. 50 zł brutto)',
          },
          noData: {
            list: {
              header: 'Brak pakietów',
              content: 'Nie udało się pobrać listy pakietów.',
            },
            details: {
              header: 'Brak informacji o połączeniu',
              content: 'Nie udało się pobrać szczegółów połączeń.',
            },
          },
        },
        settlementsList: {
          settlement: {
            currency: 'zł',
          },
        },
        wallet: {
          button: 'Doładuj konto',
          currency: 'zł',
          text1: 'Stan twojego konta',
          text2: 'Kwota wolna do wykorzystania',
          text3: 'Kwota zajęta po akceptacji wyceny',
        },
        modal: {
          topText: 'Brak wystarczających środków na koncie',
          bottomText: 'Aby zaakceptować wycenę należy doładować konto',
          button: 'Doładuj konto',
        },
      },
      switchLawyer: {
        firstNamePlaceholder: 'Imię',
        lastNamePlaceholder: 'Nazwisko',
        positionPlaceholder: 'Stanowisko',
        statusPlaceholder: 'Status',
      },
    },
    userManagement: {
      registration: {
        email: {
          info: 'Aby stworzyć konto wpisz poniżej adres e-mail lub numer telefonu',
          inputPlaceholder: 'E-mail lub telefon',
          button1: 'Zarejestruj się',
          button2: 'Mam już konto',
          success: {
            topText: 'Na podany adres e-mail została wysłana wiadomość',
            bottomText: 'Postępuj zgodnie z instrukcją aby zakończyć proces tworzenia konta',
          },
          inputError: {
            message: 'Błędny adres e-mail',
          },
          header: 'Zarejestruj',
        },
        sms: {
          firstStep: {
            info: 'Aby stworzyć konto wpisz poniżej numer telefonu',
            inputPlaceholder: 'Wpisz numer telefonu',
            button1: 'Zacznij',
            button2: 'Mam już konto',
            success: {},
            inputError: {
              message: 'Błędny numer telefonu',
            },
          },
          secondStep: {
            info: 'Wysłaliśmy SMS z kodem potwierdzającym. Wprowadź go poniżej aby potwierdzić swój numer telefonu',
            inputPlaceholder: 'Wpisz otrzymany kod',
            button: 'Przejdź dalej',
            inputError: {
              message: 'Niepoprawny kod',
            },
          },
        },
        common: {
          thirdStep: {
            info: 'Aby zakończyć proces rejestracji należy wprowadzić hasło.',
            inputPasswordPlaceholder: 'Wprowadź hasło',
            inputConfirmPasswordPlaceholder: 'Potwierdź hasło',
            buttonText: 'Załóż konto',
            passwordErrors: {
              isSamePassword: 'Hasła muszą być takie same',
              haveAtLeastEightCharacters: 'Hasło musi zawierać przynajmniej 8 znaków',
              haveAtLeastOneLowerCaseCharacter: 'Hasło musi zawierać przynajmniej jedną małą literę',
              haveAtLeastOneNumber: 'Hasło musi zawierać przynajmniej jedną cyfrę',
              haveAtLeastOneUpperCaseCharacter: 'Hasło musi zawierać przynajmniej jedną dużą literę',
            },
          },
          fourthStep: {
            topText: 'Dziękujemy za rejestrację w naszym systemie.',
            bottomText: 'Za chwilę zostaniesz przekierowany na stronę z wyborem dziedziny',
          },
        },
        modal: {
          topText: 'Dziękujemy za rejestrację w naszym systemie.',
          bottomText: 'Za chwilę zostaniesz przekierowany na stronę z wyborem dziedziny',
        },
      },
      login: {
        header: 'Zaloguj',
        info: {
          part1: 'Zaloguj się używając formularza poniżej.',
          part2: 'Możesz zalogować się używając numeru telefonu lub adresu e-mail',
        },
        inputEmailPhonePlaceholder: 'E-mail lub telefon',
        inputPasswordPlaceholder: 'Hasło',
        button1: 'Zaloguj się',
        button2: 'Zapomniałem hasła >',
        blockedAccount: {
          topText: 'Twoje konto jest zablokowane',
          bottomText: 'Poproś o pomoc w sekretariacie. Za utrudnienia przepraszamy.',
        },
      },
      forgotPassword: {
        header: 'Przypomnienie hasła',
        info: 'Aby przypomnieć hasło wpisz poniżej adres e-mail lub numer telefonu',
        inputPlaceholder: 'E-mail lub telefon',
        button: 'Wyślij',
        success: {
          email: {
            topText: 'Na podany adres e-mail została wysłana wiadomość',
            bottomText: 'Postępuj zgodnie z instrukcją aby zakończyć proces zmiany hasła',
          },
          phone: {
            topText: 'Na podany numer telefonu została wysłana wiadomość SMS',
            bottomText: 'Postępuj zgodnie z instrukcją aby zakończyć proces zmiany hasła',
          },
        },
        modal: {
          topText: 'Pomyślnie udało się zresetować hasło',
          bottomText: 'Za chwilę zostaniesz przekierowany na stronę logowania',
        },
        common: {
          thirdStep: {
            info: 'Aby zakończyć proces zmiany hasła należy wprowadzić hasło',
            buttonText: 'Zmień hasło',
          },
        },
      },
      verifyCode: {
        header: 'Zarejestruj',
        info: 'Aby zweryfikować e-mail wpisz poniżej otrzymany kod',
        inputPlaceholder: 'Wpisz kod',
        button: 'Wyślij',
        footer: {
          info: 'Wiadomość e-mail nie dotarła?',
          button: 'Spróbuj ponownie',
        },
        inputError: {
          message: 'Błędny kod',
        },
      },
      footer: {
        registration: {
          info: 'Nie masz jeszcze konta?',
          button: 'Zarejestruj się',
        },
        login: {
          info: 'Posiadasz już konto?',
          button: 'Zaloguj się',
        },
      },
    },
    main: {
      valuation: {
        row1: {
          title: 'Typ:',
          option1: 'U',
          option2: 'S',
        },
        row2: {
          title: 'Temat:',
          placeholder: 'Wpisz temat',
        },
        row3: {
          title: 'Koszt:',
          placeholder: 'Wpisz koszt',
        },
        row4: {
          title: 'Prowizja od:',
          placeholder: 'Wpisz prowizję',
        },
        row5: {
          title: 'Kwota bazowa: ',
          placeholder: 'Kwota bazowa',
        },
        row6: {
          title: 'Prowizja: ',
          placeholder: 'Wartość prowizji',
        },
        row7: {
          net: 'zł netto',
          gross: 'zł brutto',
        },
        button1: 'Edytuj',
        button2: 'Zapisz',
        inquiryAccepted: 'Klient zaakceptował wycenę',
      },
      payment: {
        actions: {
          button: 'Edytuj',
          button1: 'Zapisz',
        },
        summary: {
          row1: {
            title: 'Typ płatności:',
            option1: 'C',
            option2: 'Z',
          },
          row2: {
            title: 'Cena za usługę:',
            option1: 'zł netto',
            option2: 'zł brutto',
          },
          row3: {
            title: 'Zaliczka:',
          },
          row4: {
            title: 'Raty: ',
            option1: '1: ',
            option2: '2: ',
            option3: '3: ',
          },
        },
      },
      chat: {
        messageForm: {
          placeholder: 'Napisz wiadomość...',
        },
      },
      common: {
        box: {
          button: {
            normal: 'Przełącz',
          },
          info: '',
        },
      },
    },
    common: {
      inputs: {
        email: 'Email',
        password: 'Password',
      },
      inputErrors: {
        emailEmpty: 'Email nie może być pusty',
        emailIncorrect: 'Email nie jest poprawny',
        phoneIncorrect: 'Numer telefonu nie jest poprawny',
        fieldEmpty: 'Pole nie może być puste',
        fieldIncorrect: 'To nie jest email/telefon',
        noData: 'Brak danych',
      },
      clock: {
        seconds: 'sekund',
        minutes: {
          part1: 'Upłynęło',
          part2: 'minut',
          part3: 'konsultacji',
        },
      },
      nav: {
        login: 'Zaloguj',
        logout: 'Wyloguj',
        block: 'Zablokuj',
      },
      blockScreen: {
        unlock: 'Odblokuj',
        unlockHeader: 'Aby odblokować wpisz hasło:',
      },
      error: {
        technical: 'Wystąpiły problemy techniczne,\n' + 'prosimy o kontakt w sekretariacie.',
        server: 'Wystąpiły problemy techniczne,\n' + 'trwają prace na serwerze.',
        notFound: 'Nie znaleziono strony o podanym adresie.',
        underMaintenance: 'Trwają prace konserwacyjne.',
        bottomInfo: 'Za utrudnienia przepraszamy',
        additionalInfo: 'Skontaktuj się z sekretarką',
        button: 'Odśwież',
      },
      sidenav: {
        options: {
          name1: 'dziedziny prawa',
          name2: 'historia połączeń',
          name3: 'wiadomości',
          name4: 'usługi',
          name5: 'rozliczenia',
          name6: 'profil',
        },
      },
    },
    errors: {
      AccountBanned: 'Konto zbanowane',
      AccountDisabled: 'Konto wyłączone',
      RoleIsNotServed: 'Rola nie jest obsługiwana',
      CaptchaRequired: 'Captcha jest wymagana',
      CaptchaFailed: 'Captcha nieprawidłowa',
      ThirdPartyApi: 'Błąd zewnętrzny',
      NoWhileCall: 'Nie można w trakcie rozmowy',
      Call: 'Nie udało się nawiązać połączenia',
      CallNotFound: 'Rozmowa nie znaleziona',
      InternalError: 'Błąd wewnętrzny',
      ActionNotFound: 'Nie znaleziono akcji',
      ActionIsEmpty: 'Akcja jest pusta',
      NatsError: 'Błąd komunikacji wewnętrznej mikrosewisów',
      WrongParameters: 'Złe dane wychodzące',
      WrongActionId: 'Złe id akcji',
      MissingController: 'Błąd wewnętrzny',
      MissingAction: 'Zła akcja',
      AccessDenied: 'Brak dostępu',
      LoginRequired: 'Wymagane zalogowanie',
      MissingBrowserToken: 'Brak tokena',
      AlreadyExist: 'Już istnieje',
      NotFound: 'Nie znaleziono',
      sqlError: 'Błąd komunikacji z bazą danych',
      InvalidSecret: 'Nieprawidłowy sekretny kod',
      WhileAuthProcess: 'Process autoryzacyjny w trakcie',
      AlreadyUpdated: 'Już zaktualizowane',
      LawyerNotRelatedWithService: 'Prawnik nie powiązany z usługą',
      NotString: 'Złe dane wychodzące - nie ciąg znaków',
      HourIsNotInteger: 'Złe dane wychodzące - godzina nie jest liczbą',
      IpIsNotValid: 'Złe dane wychodzące - nieprawidłowe ip',
      NotLowerCase: 'Złe dane wychodzące - nie małą literą',
      NotUpperCase: 'Złe dane wychodzące - nie wielką literą',
      NotNumber: 'Złe dane wychodzące - nie jest liczbą',
      NotValidRegex: 'Złe dane wychodzące - nieprawidłowy regex',
      MissingValue: 'Złe dane wychodzące - brakująca wartość',
      MissingField: 'Złe dane wychodzące - brakujące pole',
      TooOldData: 'Złe dane wychodzące - nieaktualne dane',
      IsType: 'Złe dane wychodzące - jest typem',
      UndefinedTypeOfField: 'Złe dane wychodzące - niezdefiniowany typ pola',
      MissingAny: 'Błąd wewnętrzny',
      inArray: 'Złe dane wychodzące - w tablicy',
      valueHigherThanMax: 'Złe dane wychodzące - wartość większa niż maksymalna',
      valueLowerThanMin: 'Złe dane wychodzące - wartość mniejsza niż minimalna',
      InvalidFileExtension: 'Złe dane wychodzące - Nieprawidłowe rozszerzenie pliku',
      OffsetWithoutLimit: 'Złe dane wychodzące - przesuniącie bez limitu',
      notEnoughMoney: 'Za mało pieniędzy',
      methodNotAllowed: 'Metoda niedozwolona',
      paymentNotSuccessfull: 'Płatność nie powiodła się',
      InquiryNotAccepted: 'Wycena nie zakceptowana',
      PaymentAlreadyPrepared: 'Płatność już przygotowana',
      PaymentAlreadyResolved: 'Płatność już zakończona',
      AmountsHasDiverged: 'Wartości są rozbieżne',
      InvalidCredentials: 'Nieprawidłowy email lub hasło',
    },
  },
};
