export const documentEditTranslations = {
  "pl-PL": {
    documenteditor: {
      Table: "Tabela",
      Row: "Wiersz",
      Cell: "Komórka",
      Ok: "Ok",
      Cancel: "Zamknij",
      Size: "Rozmiar",
      "Preferred Width": "Preferowana szerokość",
      Points: "Punkty",
      Percent: "Procent",
      "Measure in": "Zmierz w",
      Alignment: "Wyrównanie",
      Left: "Lewo",
      Center: "Środek",
      Right: "Prawo",
      Justify: "Wyjustowane",
      Options: "Opcje",
      Top: "Góra",
      Bottom: "Dół",
      "Default cell margins": "Domyślne marginesy",
      "Default cell spacing": "Domyśle odstępy",
      Borders: "Obramowanie",
      None: "Brak",
      Dot: "Kropka",
      Width: "Szerokość",
      Height: "Wysokość",
      Letter: "Litera",
      Header: "Nagłówek",
      Footer: "Stopka",
      Margin: "Marginesy",
      Orientation: "Orientacja",
      Landscape: "Pozioma",
      Portrait: "Pionowa",
      Reset: "Zresetuj",
      "Whole words": "Tylko całe słowa",
      Add: "Dodaj",
      "Go To": "Przejdź do",
      "Search for": "Szukaj",
      "Replace with": "Zastąp",
      Navigation: "Nawigacja",
      Find: "Znajdź",
      "Match case": "Uwzględnij wielkość liter",
      "Insert Table": "Wstaw Tabele",
      "Number of columns": "Ilość kolumn",
      "Number of rows": "Ilość wierszy",
      "Page Setup": "Ustawienia strony",
      Result: "Wynik",
      of: "z",
    },
  },
};
