export interface ClientAppointment {
  id: string;
  start: string;
  end: string;
  topic: string;
  comments: string;
  status: AppointmentStatus;
  isFree: boolean;
  sessionId: string;
  lawyerId: number;
  domain: string;
  lawyer: string;
  serviceNumber: string;
}

export interface ClientService {
  id: string;
  title: string;
  number: string;
  status: ServiceStatus;
  lawyerId: number;
  domain: string;
  sessionId: string;
  stages: ClientServiceStage[];
}

export interface ClientServiceStage {
  description: string;
  date: string;
}

export interface Lawyer {
  id: number;
  name: string;
  surname: string;
  licenceNumber: string;
  description: string;
  education: string;
  userId: number;
  imageId: number;
  lawyerStatus: LawyerStatus;
  isVerified: boolean;
  isActive: boolean;
  domains: LawyerDomain[];
}

export interface DomainBase {
  id: number;
  name: string;
}

export interface LawyerDomain extends DomainBase {
  experience: string;
  isMain: boolean;
}

export interface Domain extends DomainBase {
  keywords: string;
  description: string;
  imageId: string;
  imageLocation: string;
}

export enum LawyerStatus {
  LAWYER = "Prawnik",
  LEGAL_CONSUEL = "Radca prawny",
  ADVOCATE = "Adwokat",
}

export enum UserRole {
  Customer = "customer",
  Lawyer = "lawyer",
  Secretary = "secretary",
  Dealer = "dealer",
  Admin = "admin",
}

export enum ServiceStatus {
  OPEN = "Otwarte",
  CLOSED = "Zamknięte",
}

export enum ServiceItemType {
  Monit = 1,
  Pismo,
  Rozprawa,
  Dokumenty,
  Ugoda,
  Termin,
  Platnosc,
  Konsultacja,
  Inne,
  Zakonczone,
}

export const ServiceItemTypeIdByString = {
  Monit: 1,
  Pismo: 2,
  Rozprawa: 3,
  Dokumenty: 4,
  Ugoda: 5,
  Termin: 6,
  Płatność: 7,
  Konsultacja: 8,
  Inne: 9,
  "Zlecenie zakończone": 10,
};

export enum ServiceItemMode {
  Etap = 1,
  Notatka,
}

export enum AppointmentStatus {
  PLANNED = "Planned",
  PAYMENT_PENDING = "Payment pending",
  ENDED = "Ended",
}

export enum RightNavBarOption {
  actions = 0,
  person,
  description,
  enemy,
  offices,
  consequences,
  condition,
  witnesses,
  additional,
  documentation,
  // agreement,
  // guardian
}

export interface IRightNavBarStatus {
  value: boolean;
}
export interface IRightNavBarOption {
  option: RightNavBarOption;
}
