import { ImmutableMap } from "common/helpers/ImmutableHelpers";
import { List } from "immutable";
import { omit } from "lodash";
import isJSON from "validator/es/lib/isJSON";
const moment = require("moment");

export const MimeTypesMap = {
  png: "image/png",
  jpg: "image/jpg",
  jpeg: "image/jpeg",
  pdf: "application/pdf",
  doc: "application/msword",
  docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ppt: "application/vnd.ms-powerpoint",
  pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  xls: "application/vnd.ms-excel",
  odt: "application/vnd.oasis.opendocument.text",
  txt: "text/plain",
};

export const roundNumber = (value: number, roundTo: number = 2) => {
  return Number(value.toFixed(roundTo));
};

export const numberWithSpaces = (value: number): string => {
  if (value) {
    const numberToConvert =
      Math.floor(value) - value === 0 ? value.toString() : value.toFixed(2);
    return numberToConvert.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  } else {
    return "0";
  }
};

export const addZeroBeforeNumber = (value: number, roundTo: number = 4) => {
  return ("0".repeat(roundTo) + value).slice(Math.abs(roundTo) * -1);
};

export const msToHMS = (ms: number | null): string => {
  const { duration } = require("moment");
  const durationObj = duration(ms, "milliseconds");
  const hours = durationObj.hours();
  const minutes = durationObj.minutes();
  const seconds = durationObj.seconds();

  return ms
    ? (hours ? `${hours}h ` : "") +
        (minutes ? `${minutes}min ` : "") +
        (ms ? `${seconds || 0}s` : "")
    : "0s";
};

export const parseHour = (hourString: string) => {
  const [hours, minutes] = hourString.split(":");
  return [parseInt(hours, 10), parseInt(minutes, 10)];
};

export const capitalizeFirstLetter = (value: string): string => {
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export const currencyConverter = (value: number): number => {
  return value / 100;
};

export const currencyRound = (value: number): number => {
  return Math.round(value * 100) / 100;
};

export const generatePictureUrl = (
  id: number,
  isBranch: boolean = true,
  extension: string = "png"
): string => {
  // const config = require('lawpoint-common/config/files.json');

  // return `${(config.url) || 'https://public.competia-panel.pl'}/${
  //   isBranch ? 'branch' : 'lawyer'}_${id}_800.${extension}`;

  return "url_do_podmiany";
};

export const isSamePath = (currentUrl: string, routerUrl: string): boolean => {
  const index: number = routerUrl.indexOf(":");

  if (index === -1) {
    return currentUrl === routerUrl;
  }
  return currentUrl.substr(0, index) === routerUrl.substr(0, index);
};

export const getNameInitials = (name: string) => {
  const initials: RegExpMatchArray = name.split(" ") || [];

  return (
    (initials.shift() || "")[0] + (initials.pop() || "")[0]
  ).toUpperCase();
};

export const netToGrossPrice = (value: number) => {
  return roundNumber(value * 1.23);
};

export const grossToNetPrice = (value: number) => {
  return roundNumber(value / 1.23);
};

export const getPercentageValue = (value: number, percentage: number) => {
  return roundNumber((percentage / 100) * value);
};

export const moveArrayElement = (
  array: number[],
  fromIndex: number,
  toIndex: number
): number[] => {
  array.splice(toIndex, 1, array.splice(fromIndex, 1)[0]);

  return array;
};

export const transformBase64StringToUintArray = (baseString: string) => {
  const bytes = atob(baseString);
  const byteNumbers = new Array(bytes.length);
  for (let i = 0; i < bytes.length; i++) {
    byteNumbers[i] = bytes.charCodeAt(i);
  }

  return new Uint8Array(byteNumbers);
};

export const nl2br = (str: string) => {
  const React = require("react");
  const newlineRegex = /(\r\n|\r|\n)/g;

  if (typeof str === "number") {
    return str;
  } else if (typeof str !== "string") {
    return "";
  }

  return str.split(newlineRegex).map((line: string, index: number) => {
    if (line.match(newlineRegex)) {
      return React.createElement("br", { key: index });
    }

    return line;
  });
};
export const arrayBufferToBase64 = (buffer: ArrayBuffer) => {
  let binary = "";
  const bytes = new Uint8Array(buffer);
  for (let i = 0; i < bytes.byteLength; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
};
export const truncateWithEllipses = (
  text: string,
  max: number,
  ellipses: string = "..."
) => text.substr(0, max - 1) + (text.length > max ? ellipses : "");

export const isAlreadySaved = (
  immutableMap: List<ImmutableMap<any>>,
  idToCheck: number,
  serviceId: number
): boolean => {
  if (immutableMap && typeof immutableMap === "object") {
    return !!immutableMap.find((elem) => {
      return (
        elem.get("document_id") === idToCheck &&
        elem.get("service_id") === serviceId
      );
    });
  }

  return true;
};

export const hasEmptyKeys = (immutableMap: ImmutableMap<any>): boolean => {
  if (immutableMap && typeof immutableMap === "object") {
    const result = immutableMap.filter(
      (object: any) => !object || object.size === 0
    );

    return result ? result.size === immutableMap.size : false;
  }

  return !immutableMap;
};

export const hasAtLeastOneObject = (
  immutableMap: ImmutableMap<any>
): boolean => {
  const result = immutableMap.filter(
    (object: any) => !object || object.size === 0
  );

  return !!result;
};

export const hasEmptyValues = (immutableMap: ImmutableMap<any>): boolean => {
  if (immutableMap && typeof immutableMap === "object") {
    const result = immutableMap.filter((object: any) => {
      if (object && typeof object === "object") {
        return hasEmptyValues(object);
      }

      return !object || object.size === 0;
    });

    return result ? result.size === immutableMap.size : false;
  }

  return !immutableMap;
};

export const removeSuccess = (data: any) => {
  return omit(data, "success");
};

export const isNullValue = (
  value: string | number | null | undefined,
  emptyString?: boolean
) => {
  return !(value || value === 0 || (emptyString && value === ""));
};

export const changeNullToEmptyString = (data: any): any => {
  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      if (data[key] && typeof data[key] === "object") {
        for (const i in data[key]) {
          if (data[key].hasOwnProperty(i)) {
            data[key][i] = !isNullValue(data[key][i]) ? data[key][i] : "";
          }
        }
      } else {
        data[key] = !isNullValue(data[key]) ? data[key] : "";
      }
    }
  }
};

export const changeEmptyStringsToNull = (data: any): any => {
  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      if (data[key] && typeof data[key] === "object") {
        for (const i in data[key]) {
          if (data[key].hasOwnProperty(i)) {
            data[key][i] = data[key][i] === "" ? null : data[key][i];
          }
        }
      } else {
        data[key] = data[key] === "" ? null : data[key];
      }
    }
  }
};

export const changeNestedNullToEmptyString = (data: any) => {
  if (data && typeof data === "object") {
    const result = data.map((object: any) => {
      if (object && typeof object === "object") {
        return changeNestedNullToEmptyString(object);
      }
      if (typeof object === "boolean") return object;
      return typeof object === "number" ? object : object || "";
    });

    return result;
  }

  if (typeof data == "boolean") return data;

  return data || "";
};

export const trimObjectValues = (data: any) => {
  for (const field of Object.keys(data)) {
    const value = data[field];
    const type = typeof value;
    if (value && (type === "string" || type === "object")) {
      if (type === "object") {
        trimObjectValues(data[field]);
      } else {
        data[field] = data[field].trim();
      }
    }
  }
};

export const changePenniesToPLN = (data: any) => {
  ["amount", "provision"].forEach((field) => {
    data[field] = currencyConverter(data[field]);
  });

  return data;
};

export const forceRefresh = () => {
  window.location.reload(true);
};

export const forceGoHome = () => {
  window.location.href = "/";
};

export const trimValue = (value: string | null | undefined) =>
  (value || "").trim();

export const twoDigits = (num: number | string) => {
  if (!num) {
    return "00";
  }
  let numStr;
  if (typeof num === "number") {
    numStr = num.toString();
  } else {
    numStr = num;
  }
  if (numStr.length < 2) {
    return `0${numStr}`;
  }
  return numStr;
};

export const msToTime = (millis: number = 0) => {
  const hours = Math.floor(millis / 3600000);
  let millisLeft = millis - hours * 3600000;
  const minutes = Math.floor(millisLeft / 60000);
  millisLeft -= minutes * 60000;
  const seconds = Math.floor(millisLeft / 1000);
  return `${twoDigits(hours)}:${twoDigits(minutes)}:${twoDigits(seconds)}`;
};

export const peselToBirthDate = (pesel: string) => {
  if (pesel && pesel.match(/^[0-9]{11}$/)) {
    const centuryIndicator = pesel[2];
    let yearPrefix = "19";
    let monthModificator = 0;
    if (!(centuryIndicator === "0" || centuryIndicator === "1")) {
      if (centuryIndicator === "2" || centuryIndicator === "3") {
        yearPrefix = "20";
        monthModificator = 20;
      } else if (centuryIndicator === "8" || centuryIndicator === "9") {
        yearPrefix = "18";
        monthModificator = 80;
      } else if (centuryIndicator === "4" || centuryIndicator === "5") {
        yearPrefix = "21";
        monthModificator = 40;
      } else if (centuryIndicator === "6" || centuryIndicator === "7") {
        yearPrefix = "22";
        monthModificator = 60;
      }
    }
    const yearOfBirth = yearPrefix + pesel.slice(0, 2);
    const monthOfBirth = Number(pesel.slice(2, 4)) - monthModificator;
    const dayOfBirth = pesel.slice(4, 6);
    const date = `${dayOfBirth}/${
      monthOfBirth < 10 ? "0" + monthOfBirth : monthOfBirth
    }/${yearOfBirth}`;
    return moment(date, "DD/MM/YYYY").isValid() ? date : "";
  }
  return "";
};

export const encodeBase64String = (objectToEncode) => {
  const base64 = Buffer.from(JSON.stringify(objectToEncode)).toString("base64");
  return encodeURIComponent(base64);
};

export const decodeBase64String = (encoded) => {
  const base64 = decodeURIComponent(encoded);
  const decoded = Buffer.from(base64, "base64").toString("utf8");
  let dataJson = false;

  if (isJSON(decoded)) {
    dataJson = JSON.parse(decoded);
    return dataJson;
  } else {
    return false;
  }
};

export const determineClientName = (client: any) => {
  if (!client) return;

  if (client.represented && client.represented.name) {
    return `${client.represented.name} ${client.represented.surname}`;
  } else if (client.company && client.company.shortName) {
    return `${client.company.shortName}`;
  } else if (client.company && client.company.name) {
    return `${client.company.name}`;
  } else {
    return `${client.name} ${client.surname}`;
  }
};

export const determineRepresentedName = (client: any) => {
  if (client.represented && client.represented.name) {
    return `${client.represented.name} ${client.represented.surname}`;
  } else if (client.company && client.company.shortName) {
    return `${client.company.shortName}`;
  } else if (client.company && client.company.name) {
    return `${client.company.name}`;
  } else {
    return `${client.name} ${client.surname}`;
  }
};

export const determineRepresentedDocNumber = (
  client: any,
  withLabel: boolean
) => {
  if (client.represented && client.represented.name) {
    return client.represented.PESEL
      ? withLabel
        ? `PESEL: ${client.represented.PESEL}`
        : client.represented.PESEL
      : null;
  } else if (
    client.company &&
    (client.company.name || client.company.shortName)
  ) {
    return client.company.NIP
      ? withLabel
        ? `NIP: ${client.company.NIP}`
        : client.company.NIP
      : null;
  } else if (client.PESEL) {
    return withLabel ? `PESEL: ${client.PESEL}` : client.PESEL;
  } else return "";
};

export const stripHTML = (text: string) => {
  return decodeEntities(text).replace(/(<([^>]+)>)/gi, "");
};

const decodeEntities = (html: string) => {
  var txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value;
};
