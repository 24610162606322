import { action } from "typesafe-actions";

import { RightNavBarActionTypes } from "src/action-types/RightNavBar";

export enum RightNavBarOption {
  actions = 0,
  person,
  description,
  enemy,
  offices,
  consequences,
  condition,
  witnesses,
  additional,
  documentation,
  // agreement,
  // guardian
}

export interface IRightNavBarStatus {
  value: boolean;
}
export interface IRightNavBarOption {
  option: RightNavBarOption;
}

export const toggleRightNavBar = (value: boolean) =>
  action(RightNavBarActionTypes.TOGGLE, value);

export const selectRightNavBarOption = (option: RightNavBarOption) =>
  action(RightNavBarActionTypes.SELECT_OPTION, option);

export const toggleEdit = (value: boolean) =>
  action(RightNavBarActionTypes.TOGGLE_EDIT, value);

export const toggleChange = (value: boolean) =>
  action(RightNavBarActionTypes.TOGGLE_CHANGE, value);
