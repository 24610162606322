//pobieranie listy klientów
export const SET_CLIENTS = "SET_CLIENTS";
export const SET_CLIENTS_LIST_LOADING = "SET_CLIENTS_LIST_LOADING";

//pobieranie szczegółów klienta
export const SET_CLIENT_ID = "SET_CLIENT_ID";
export const SET_CLIENT_DETAILS = "SET_CLIENT_DETAILS";
export const SET_CLIENT_DETAILS_LOADING = "SET_CLIENT_DETAILS_LOADING";

export const SET_CLIENT_EDIT = "SET_CLIENT_EDIT";
export const SET_CLIENT_CREATE = "SET_CLIENT_CREATE";

export const SET_CLIENT_UPDATE_SUCCESSFUL = "SET_CLIENT_UPDATE_SUCCESSFUL";

//right navbar
export const RIGHT_NAVBAR_TOGGLE = "RIGHT_NAVBAR_TOGGLE";
export const RIGHT_NAVBAR_SELECT_OPTION = "RIGHT_NAVBAR_SELECT_OPTION";
export const RIGHT_NAVBAR_TOGGLE_EDIT = "RIGHT_NAVBAR_TOGGLE_EDIT";
export const RIGHT_NAVBAR_TOGGLE_CHANGE = "RIGHT_NAVBAR_TOGGLE_CHANGE";

//domains
export const SET_DOMAINS = "SET_DOMAINS";
export const SET_DOMAIN_TYPES = "SET_DOMAIN_TYPES";

//customer
export const SET_CUSTOMER_APPOINTMENTS = "SET_CUSTOMER_APPOINTMENTS";
export const SET_CUSTOMER_AUTOCOMPLETE_SERVICES =
  "SET_CUSTOMER_AUTOCOMPLETE_SERVICES";
export const SET_CUSTOMER_AUTOCOMPLETE_LAWYERS =
  "SET_CUSTOMER_AUTOCOMPLETE_LAWYERS";
export const SET_CUSTOMER_APPOINTMENTS_LOADING =
  "SET_CUSTOMER_APPOINTMENTS_LOADING";
export const SET_CUSTOMER_APPOINTMENTS_LAWYER =
  "SET_CUSTOMER_APPOINTMENTS_LAWYER";
export const SET_CUSTOMER_APPOINTMENTS_ID = "SET_CUSTOMER_APPOINTMENTS_ID";
export const SET_CUSTOMER_APPOINTMENT_CALL = "SET_CUSTOMER_APPOINTMENT_CALL";
export const SET_CUSTOMER_SERVICE = "SET_CUSTOMER_SERVICE";
export const SET_CUSTOMER_SERVICE_ID = "SET_CUSTOMER_SERVICE_ID";
export const SET_CUSTOMER_SERVICE_OBJ = "SET_CUSTOMER_SERVICE_OBJ";
export const SET_CUSTOMER_SERVICES = "SET_CUSTOMER_SERVICES";
export const SET_CUSTOMER_MESSAGES = "SET_CUSTOMER_MESSAGES";
export const SET_CUSTOMER_APPOINTMENT_MESSAGES =
  "SET_CUSTOMER_APPOINTMENT_MESSAGES";
export const SET_CUSTOMER_APPOINTMENT = "SET_CUSTOMER_APPOINTMENT";
export const SET_CUSTOMER_MESSAGE = "SET_CUSTOMER_MESSAGE";
export const SET_CUSTOMER_MESSAGE_ID = "SET_CUSTOMER_MESSAGE_ID";

//lawyer calls
export const SET_LAWYER_APPOINTMENTS = "SET_LAWYER_APPOINTMENTS";
export const SET_LAWYER_APPOINTMENTS_LOADING =
  "SET_LAWYER_APPOINTMENTS_LOADING";
export const SET_LAWYER_APPOINTMENTS_ID = "SET_LAWYER_APPOINTMENTS_ID";
export const SET_LAWYER_APPOINTMENT_CALL = "SET_LAWYER_APPOINTMENT_CALL";
export const SET_LAWYER_APPOINTMENTS_OBJ = "SET_LAWYER_APPOINTMENTS_OBJ";
export const SET_LAWYER_APPOINTMENT_CALL_SERVICE_STEPS =
  "SET_LAWYER_APPOINTMENTS_SERVICE_STEPS";
export const SET_IS_READ = "SET_IS_READ";

//pobieranie listy klientów partnera
export const SET_DEALER_CLIENTS = "SET_DEALER_CLIENTS";
export const SET_DEALER_CLIENTS_LIST_LOADING =
  "SET_DEALER_CLIENTS_LIST_LOADING";

//pobieranie szczegółów partnera
export const SET_DEALER_CLIENT_ID = "SET_DEALER_CLIENT_ID";
export const SET_DEALER_CLIENT_DETAILS = "SET_DEALER_CLIENT_DETAILS";
export const SET_DEALER_CLIENT_DETAILS_LOADING =
  "SET_DEALER_CLIENT_DETAILS_LOADING";

export const SET_DEALER_CLIENT_EDIT = "SET_DEALER_CLIENT_EDIT";
export const SET_DEALER_CLIENT_UPDATE_SUCCESSFUL =
  "SET_DEALER_CLIENT_UPDATE_SUCCESSFUL";

export const SET_DEALERS = "SET_DEALERS";

export const SET_LAWYERS = "SET_LAWYERS";
export const SET_LAWYER = "SET_LAWYER";
