import {
  SET_DEALER_CLIENTS,
  SET_DEALER_CLIENTS_LIST_LOADING,
  SET_DEALER_CLIENT_DETAILS,
  SET_DEALER_CLIENT_DETAILS_LOADING,
  SET_DEALER_CLIENT_ID,
  SET_DEALER_CLIENT_EDIT,
  SET_DEALER_CLIENT_UPDATE_SUCCESSFUL,
} from "../../actions/types";

const initialState = {
  clientList: [],
  clientListLoading: false,
  clientDetailsLoading: false,
  clientDetails: null,
  clientCount: null,
  clientActiveId: null,
  clientEdit: false,
  clientUpdateSuccessful: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_DEALER_CLIENTS: {
      const { clientList } = action.payload;
      return {
        ...state,
        clientList: clientList,
        clientCount: clientList.length,
      };
    }
    case SET_DEALER_CLIENTS_LIST_LOADING: {
      return {
        ...state,
        clientListLoading: action.payload,
      };
    }
    case SET_DEALER_CLIENT_DETAILS: {
      const { clientDetails } = action.payload;
      return {
        ...state,
        clientDetails: clientDetails,
      };
    }
    case SET_DEALER_CLIENT_DETAILS_LOADING: {
      return {
        ...state,
        clientDetailsLoading: action.payload,
      };
    }
    case SET_DEALER_CLIENT_ID: {
      return {
        ...state,
        clientActiveId: action.payload,
      };
    }
    case SET_DEALER_CLIENT_EDIT: {
      return {
        ...state,
        clientEdit: action.payload,
      };
    }
    case SET_DEALER_CLIENT_UPDATE_SUCCESSFUL: {
      return {
        ...state,
        clientUpdateSuccessful: action.payload,
      };
    }
    default:
      return state;
  }
}
